import { ListParams, RequestModel, ResponseModel, RegistersDrivingRangesModel } from 'models';
import axiosCustom from './axiosCustom';

const registersDrivingRangesApi = {
  getAll(params: ListParams): Promise<ResponseModel<RegistersDrivingRangesModel>> {
    const url = '/api/dcm/registers_driving_ranges';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<RegistersDrivingRangesModel>> {
    const url = `/api/dcm/registers_driving_ranges/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<RegistersDrivingRangesModel>): Promise<ResponseModel<RegistersDrivingRangesModel>> {
    const url = '/api/dcm/registers_driving_ranges';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<RegistersDrivingRangesModel>): Promise<ResponseModel<RegistersDrivingRangesModel>> {
    const url = `/api/dcm/registers_driving_ranges/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<RegistersDrivingRangesModel>> {
    const url = `/api/dcm/registers_driving_ranges/${id}`;
    return axiosCustom.delete(url);
  },
};

export default registersDrivingRangesApi;
