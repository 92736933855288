import { PayloadAction } from '@reduxjs/toolkit';
import registrationSchedulesApi from 'api/registrationSchedulesApi';
import { ListParams, ResponseModel, RegistrationSchedulesModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { registrationSchedulesActions } from './registrationSchedulesSlice';

function* fetchRegistrationSchedulesList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<RegistrationSchedulesModel> = yield call(
      registrationSchedulesApi.getAll,
      action.payload,
    );
    yield put(registrationSchedulesActions.fetchRegistrationSchedulesListSuccess(response));
  } catch (error) {
    yield put(registrationSchedulesActions.fetchRegistrationSchedulesListFailed());
  }
}

export default function* registrationSchedulesSaga() {
  yield takeLatest(registrationSchedulesActions.fetchRegistrationSchedulesList.type, fetchRegistrationSchedulesList);
}
