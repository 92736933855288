import { UserModel } from 'models/user.model';
import axiosCustom from './axiosCustom';

const meApi = {
  me(): Promise<UserModel> {
    const url = '/api/dcm/me';
    return axiosCustom.get(url);
  },
  login(username: string, password: string): Promise<UserModel> {
    const url = '/api/dcm/me/login';
    return axiosCustom.post(url, { username, password });
  },
  logout(): Promise<any> {
    const url = '/api/dcm/me/logout';
    return axiosCustom.post(url);
  },
};

export default meApi;
