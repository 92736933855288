export const STUDENT_CONST = {
  ADD_ERROR: 'Thêm học viên thất bại!',
  ADD_SUCCESS: 'Thêm học viên thành công!',
  ADD_TITLE: 'Thêm mới học viên',
  AMOUNT_PAID: 'Đã thanh toán',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa học viên',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa học viên',
  DELETE_ERROR: 'Xóa học viên thất bại!',
  DELETE_SUCCESS: 'Đã xóa học viên thành công!',
  EDIT_TITLE: 'Cập nhật học viên',
  HOUR_COMPLETED: 'Số giờ đã chạy',
  KM_COMPLETED: 'Số km đã đi',
  STUDENT: 'Học viên',
  UPDATE_ERROR: 'Cập nhật học viên thất bại!',
  UPDATE_SUCCESS: 'Cập nhật học viên thành công!',
};
