import { ListParams, RequestModel, ResponseModel, RegistrationSchedulesModel } from 'models';
import axiosCustom from './axiosCustom';

const registrationSchedulesApi = {
  getAll(params: ListParams): Promise<RegistrationSchedulesModel[]> {
    const url = '/api/dcm/registration_schedules';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<RegistrationSchedulesModel>> {
    const url = `/api/dcm/registration_schedules/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<RegistrationSchedulesModel>): Promise<ResponseModel<RegistrationSchedulesModel>> {
    const url = '/api/dcm/registration_schedules';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<RegistrationSchedulesModel>): Promise<ResponseModel<RegistrationSchedulesModel>> {
    const url = `/api/dcm/registration_schedules/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<RegistrationSchedulesModel>> {
    const url = `/api/dcm/registration_schedules/${id}`;
    return axiosCustom.delete(url);
  },
};

export default registrationSchedulesApi;
