export const DRIVING_LICENSE_CONST = {
  ADD_ERROR: 'Thêm hạng xe thất bại!',
  ADD_SUCCESS: 'Thêm hạng xe thành công!',
  ADD_TITLE: 'Thêm mới hạng xe',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa hạng xe',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa hạng xe',
  DELETE_ERROR: 'Xóa hạng xe thất bại!',
  DELETE_SUCCESS: 'Đã xóa hạng xe thành công!',
  DRIVING_LICENSE: 'Hạng xe',
  EDIT_TITLE: 'Cập nhật hạng xe',
  NAME: 'Tên hạng xe',
  UPDATE_ERROR: 'Cập nhật hạng xe thất bại!',
  UPDATE_SUCCESS: 'Cập nhật hạng xe thành công!',
};
