import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, DrivingRangeTypeModel } from 'models';

export interface DrivingRangeTypeState {
  loading: boolean;
  list: ResponseData<DrivingRangeTypeModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: DrivingRangeTypeState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const drivingRangeTypeSlice = createSlice({
  name: 'drivingRangeType',
  initialState,
  reducers: {
    fetchDrivingRangeTypeList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchDrivingRangeTypeListSuccess(state, action: PayloadAction<ResponseModel<DrivingRangeTypeModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchDrivingRangeTypeListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const drivingRangeTypeActions = drivingRangeTypeSlice.actions;

// Selectors
export const selectDrivingRangeTypeList = (state: RootState) => state.drivingRangeType.list;
export const selectDrivingRangeTypeLoading = (state: RootState) => state.drivingRangeType.loading;
export const selectDrivingRangeTypeFilter = (state: RootState) => state.drivingRangeType.filter;
export const selectDrivingRangeTypeTotal = (state: RootState) => state.drivingRangeType.total;

// Reducer
const drivingRangeTypeReducer = drivingRangeTypeSlice.reducer;
export default drivingRangeTypeReducer;
