import { PayloadAction } from '@reduxjs/toolkit';
import drivingRangeTypeApi from 'api/drivingRangeTypeApi';
import { DrivingRangeTypeModel, ListParams, ResponseModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { drivingRangeTypeActions } from './drivingRangeTypeSlice';

function* fetchDrivingRangeTypeList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<DrivingRangeTypeModel> = yield call(drivingRangeTypeApi.getAll, action.payload);
    yield put(drivingRangeTypeActions.fetchDrivingRangeTypeListSuccess(response));
  } catch (error) {
    yield put(drivingRangeTypeActions.fetchDrivingRangeTypeListFailed());
  }
}

export default function* drivingRangeTypeSaga() {
  yield takeLatest(drivingRangeTypeActions.fetchDrivingRangeTypeList.type, fetchDrivingRangeTypeList);
}
