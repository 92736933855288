export const USER_CONST = {
  ADD_ERROR: 'Thêm người dùng thất bại!',
  ADD_SUCCESS: 'Thêm người dùng thành công!',
  ADD_TITLE: 'Thêm mới người dùng',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa người dùng',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa người dùng',
  DELETE_ERROR: 'Xóa người dùng thất bại!',
  DELETE_SUCCESS: 'Đã xóa người dùng thành công!',
  UPDATE_ERROR: 'Cập nhật người dùng thất bại!',
  UPDATE_PW_TITLE: 'Cập nhật mật khẩu',
  UPDATE_SUCCESS: 'Cập nhật người dùng thành công!',
  UPDATE_TITLE: 'Cập nhật người dùng',
  USER: 'Người dùng',
};
