import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, RoleModel } from 'models';

export interface RoleState {
  loading: boolean;
  list: ResponseData<RoleModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: RoleState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    fetchRoleList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchRoleListSuccess(state, action: PayloadAction<ResponseModel<RoleModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchRoleListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const roleActions = roleSlice.actions;

// Selectors
export const selectRoleList = (state: RootState) => state.role.list;
export const selectRoleLoading = (state: RootState) => state.role.loading;
export const selectRoleFilter = (state: RootState) => state.role.filter;
export const selectRoleTotal = (state: RootState) => state.role.total;

// Reducer
const roleReducer = roleSlice.reducer;
export default roleReducer;
