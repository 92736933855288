import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, DrivingLicenseModel } from 'models';

export interface DrivingLicenseState {
  loading: boolean;
  list: ResponseData<DrivingLicenseModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: DrivingLicenseState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const drivingLicenseSlice = createSlice({
  name: 'drivingLicense',
  initialState,
  reducers: {
    fetchDrivingLicenseList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchDrivingLicenseListSuccess(state, action: PayloadAction<ResponseModel<DrivingLicenseModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchDrivingLicenseListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const drivingLicenseActions = drivingLicenseSlice.actions;

// Selectors
export const selectDrivingLicenseList = (state: RootState) => state.drivingLicense.list;
export const selectDrivingLicenseLoading = (state: RootState) => state.drivingLicense.loading;
export const selectDrivingLicenseFilter = (state: RootState) => state.drivingLicense.filter;
export const selectDrivingLicenseTotal = (state: RootState) => state.drivingLicense.total;

// Reducer
const drivingLicenseReducer = drivingLicenseSlice.reducer;
export default drivingLicenseReducer;
