import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Radio, Select, Spin, Switch, Table, Typography } from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import courseApi from 'api/courseApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { drivingLicenseActions, selectDrivingLicenseList } from 'features/driving-license/drivingLicenseSlice';
import { selectUserList, userActions } from 'features/user/userSlice';
import { CourseModel, IOptions, RequestModel, ResponseModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatNumber } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  AUTHORITIES,
  COMMON_CONST,
  COURSE_CONST,
  DRIVING_LICENSE_CONST,
  SITE_NAME,
  TEACHER_CONST,
} from '../../constants';
import {
  courseActions,
  selectCourseFilter,
  selectCourseList,
  selectCourseLoading,
  selectCourseTotal,
} from './courseSlice';

const { Title } = Typography;

interface ICourseData {
  key: React.Key;
  id: string;
  name: string;
  teacher_id: string;
  teacher_name: string;
  driving_license_id: string;
  driving_license_name: string;
  price: number;
  status: number;
  created_at: string;
  updated_at: string;
}

export default function Course() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectCourseLoading);
  const courseList = useAppSelector(selectCourseList);
  const courseFilter = useAppSelector(selectCourseFilter);
  const courseTotal = useAppSelector(selectCourseTotal);
  const teacherList = useAppSelector(selectUserList);
  const drivingLicenseList = useAppSelector(selectDrivingLicenseList);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [teacherOptions, setTeacherOptions] = useState<IOptions[]>([]);
  const [drivingLicenseOptions, setDrivingLicenseOptions] = useState<IOptions[]>([]);
  const [dataUpdate, setDataUpdate] = useState<ICourseData>();
  const [dataTable, setDataTable] = useState<ICourseData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${COURSE_CONST.COURSE} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);

    // Get teacherList + drivingLicenseList
    dispatch(
      userActions.fetchUserList({
        sort: 'fullname',
        filter: `[{"name":"_role","op":"has","val":{"name":"code","op":"eq","val":"${AUTHORITIES['TEACHER']}"}}]`,
      }),
    );
    dispatch(
      drivingLicenseActions.fetchDrivingLicenseList({
        sort: 'name',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update teacher options
  useEffect(() => {
    setTeacherOptions(
      teacherList.map((teacher) => {
        return {
          value: teacher.id || '',
          label: teacher.attributes.fullname || '',
        };
      }),
    );
  }, [teacherList]);

  // Update driving license options
  useEffect(() => {
    setDrivingLicenseOptions(
      drivingLicenseList.map((drivingLicense) => {
        return {
          value: drivingLicense.id || '',
          label: drivingLicense.attributes.name || '',
        };
      }),
    );
  }, [drivingLicenseList]);

  // Config columns in table
  const columns: ColumnsType<ICourseData> = [
    {
      title: DRIVING_LICENSE_CONST.DRIVING_LICENSE,
      dataIndex: 'driving_license_id',
      sorter: {
        compare: (a, b) => a.driving_license_name.localeCompare(b.driving_license_name),
        multiple: 7,
      },
      render: (value, record) => record.driving_license_name,
      align: 'center',
      width: 120,
    },
    {
      title: COURSE_CONST.NAME,
      dataIndex: 'name',
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 6,
      },
      width: 200,
    },
    {
      title: TEACHER_CONST.TEACHER,
      dataIndex: 'teacher_id',
      sorter: {
        compare: (a, b) => a.teacher_name.localeCompare(b.teacher_name),
        multiple: 5,
      },
      render: (value, record) => record.teacher_name,
      width: 200,
    },
    {
      title: COURSE_CONST.PRICE,
      dataIndex: 'price',
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 4,
      },
      render: (value: number) => <span>{formatNumber(value)}</span>,
      align: 'right',
      width: 150,
    },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 3,
      },
      width: 120,
      align: 'center',
      render: (value: any, record: ICourseData) => {
        return <Switch checked={value} onChange={(checked) => onChangeStatus(record, checked)} />;
      },
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateCourse(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteCourse(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshCourseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(courseFilter)]);

  // Update dataTable
  useEffect(() => {
    setDataTable(
      courseList.map((course) => {
        return {
          key: course.id || '',
          id: course.id || '',
          name: course.attributes.name || '',
          teacher_id: course.attributes.teacher_id || '',
          teacher_name: course.attributes.teacher?.fullname || '',
          driving_license_id: course.attributes.driving_license_id || '',
          driving_license_name: course.attributes.driving_license?.name || '',
          status: course.attributes.status || 0,
          price: course.attributes.price || 0,
          created_at: course.attributes.created_at || '',
          updated_at: course.attributes.updated_at || '',
        };
      }),
    );
  }, [courseList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: courseTotal,
      pageSize: courseFilter?._limit,
      current: courseFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseTotal, JSON.stringify(courseFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<ICourseData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(courseFilter)
    ) {
      dispatch(
        courseActions.setFilter({
          ...courseFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const onChangeStatus = async (record: ICourseData, checked: boolean) => {
    let res: ResponseModel<CourseModel> = {};
    const req: RequestModel<CourseModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'courses',
        id: record.id,
      },
    };
    res = await courseApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshCourseList();
    }
  };

  const refreshCourseList = () => {
    dispatch(
      courseActions.fetchCourseList({
        'page[number]': courseFilter?._page,
        'page[size]': courseFilter?._limit,
        sort: courseFilter?._sort,
      }),
    );
  };

  const handleAddCourse = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      name: '',
      teacher_id: '',
      teacher_name: '',
      driving_license_id: '',
      driving_license_name: '',
      price: 0,
      status: 1,
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateCourse = (record: ICourseData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate(record);
  };

  const handleDeleteCourse = (record: ICourseData) => {
    Modal.confirm({
      title: COURSE_CONST.CONFIRM_DELETE_TITLE,
      content: `${COURSE_CONST.CONFIRM_DELETE_CONTENT} ${record.name}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<CourseModel> = {};
        res = await courseApi.delete(record.id);
        if (res.meta?.message) {
          refreshCourseList();
          toast.success(COURSE_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(values, dataUpdate, isUpdate, courseApi, API_TYPE_CONST.COURSES);
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshCourseList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? COURSE_CONST.UPDATE_SUCCESS : COURSE_CONST.ADD_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      toast.error(isUpdate ? COURSE_CONST.UPDATE_ERROR : COURSE_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ float: 'left', margin: '8px 0' }}>
          {COURSE_CONST.COURSE}
        </Title>
        <Button
          onClick={handleAddCourse}
          icon={<PlusCircleOutlined rev={undefined} />}
          style={{ color: 'blue', float: 'right', margin: '8px 0' }}
        >
          {COMMON_CONST.ADD_NEW_BTN}
        </Button>
        <Table
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 1115 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? COURSE_CONST.EDIT_TITLE : COURSE_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
            )}
            {/* Tên khóa học */}
            <Form.Item
              name="name"
              label={COURSE_CONST.NAME}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <Input />
            </Form.Item>
            {/* Giáo viên */}
            <Form.Item
              name="teacher_id"
              label={TEACHER_CONST.TEACHER}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={teacherOptions}
              />
            </Form.Item>
            {/* Hạng xe */}
            <Form.Item
              name="driving_license_id"
              label={DRIVING_LICENSE_CONST.DRIVING_LICENSE}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={drivingLicenseOptions}
              />
            </Form.Item>
            {/* Học phí */}
            <Form.Item
              name="price"
              label={COURSE_CONST.PRICE}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <InputNumber
                style={{ width: 150 }}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            {/* Trạng thái */}
            <Form.Item name="status" label={COMMON_CONST.STATUS} className="collection-create-form_last-form-item">
              <Radio.Group>
                <Radio value={COMMON_CONST.STATUS_ON_VAL}>{COMMON_CONST.STATUS_ON}</Radio>
                <Radio value={COMMON_CONST.STATUS_OFF_VAL}>{COMMON_CONST.STATUS_OFF}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
