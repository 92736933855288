import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, UserModel } from 'models';

export interface UserState {
  loading: boolean;
  list: ResponseData<UserModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: UserState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchUserListSuccess(state, action: PayloadAction<ResponseModel<UserModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchUserListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const userActions = userSlice.actions;

// Selectors
export const selectUserList = (state: RootState) => state.user.list;
export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUserFilter = (state: RootState) => state.user.filter;
export const selectUserTotal = (state: RootState) => state.user.total;

// Reducer
const userReducer = userSlice.reducer;
export default userReducer;
