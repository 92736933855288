export const VEHICLE_CONST = {
  ADD_ERROR: 'Thêm xe thất bại!',
  ADD_SUCCESS: 'Thêm xe thành công!',
  ADD_TITLE: 'Thêm mới xe',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa xe',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa xe',
  VEHICLE: 'Xe',
  DELETE_ERROR: 'Xóa xe thất bại!',
  DELETE_SUCCESS: 'Đã xóa xe thành công!',
  EDIT_TITLE: 'Cập nhật xe',
  NAME: 'Mác xe',
  LICENSE_PLATE: 'Biển số',
  TEST_VEHICLE_NUMBER: 'Số xe sát hạch',
  REGISTER_NUMBER: 'Số đăng ký',
  VEHICLE_TYPE: 'Loại xe',
  MANUFACTURER: 'Hãng xe',
  FRAME_NUMBER: 'Số khung',
  GPXTL_LICENSE: 'Cơ quan cấp GPXTL',
  GPXTL_DATE: 'Ngày cấp GPXTL',
  GCNKD_DATE: 'Ngày cấp GCNKĐ',
  ENGINE_NUMBER: 'Số động cơ',
  GPXTL_NUMBER: 'Số GP xe TL',
  MANUFACTURE_YEAR: 'Năm sản xuất',
  COLOR: 'Màu xe',
  GPXTL_EXPIRATION_DATE: 'Ngày hết hạn GPXTL',
  GCNKD_EXPIRATION_DATE: 'Ngày hết hạn GCNKĐ',
  INSURANCE_EXPIRATION_DATE: 'Ngày hết hạn BH',
  IS_AUXILIARY_BRAKE: 'Có hệ thống phanh phụ',
  IS_EFFECTIVE: 'Có hiệu lực',
  IS_INSURANCE: 'Có bảo hiểm',
  BRAND: 'Nhãn hiệu',
  PURCHASE_DATE: 'Ngày nhập kho',
  PRICE: 'Học phí',
  UPDATE_ERROR: 'Cập nhật xe thất bại!',
  UPDATE_SUCCESS: 'Cập nhật xe thành công!',
};
