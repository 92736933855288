import authSaga from 'features/auth/authSaga';
import citySaga from 'features/city/citySaga';
import courseSaga from 'features/course/courseSaga';
import dashboardSaga from 'features/dashboard/dashboardSaga';
import drivingLicenseSaga from 'features/driving-license/drivingLicenseSaga';
import drivingRangeTypeSaga from 'features/driving-range-type/drivingRangeTypeSaga';
import maintenanceSchedulesSaga from 'features/maintenance-schedules/maintenanceSchedulesSaga';
import registersDrivingRangesSaga from 'features/registers-driving-ranges/registersDrivingRangesSaga';
import registrationSchedulesSaga from 'features/registration-schedules/registrationSchedulesSaga';
import roleSaga from 'features/role/roleSaga';
import studentSaga from 'features/student/studentSaga';
import studentsSaga from 'features/students/studentsSaga';
import userSaga from 'features/user/userSaga';
import vehicleSaga from 'features/vehicle/vehicleSaga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    authSaga(),
    citySaga(),
    courseSaga(),
    dashboardSaga(),
    drivingLicenseSaga(),
    drivingRangeTypeSaga(),
    maintenanceSchedulesSaga(),
    registersDrivingRangesSaga(),
    registrationSchedulesSaga(),
    roleSaga(),
    studentSaga(),
    studentsSaga(),
    userSaga(),
    vehicleSaga(),
  ]);
}
