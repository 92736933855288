import { ListParams, RequestModel, ResponseModel, CourseModel } from 'models';
import axiosCustom from './axiosCustom';

const courseApi = {
  getAll(params: ListParams): Promise<CourseModel[]> {
    const url = '/api/dcm/courses';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<CourseModel>> {
    const url = `/api/dcm/courses/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<CourseModel>): Promise<ResponseModel<CourseModel>> {
    const url = '/api/dcm/courses';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<CourseModel>): Promise<ResponseModel<CourseModel>> {
    const url = `/api/dcm/courses/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<CourseModel>> {
    const url = `/api/dcm/courses/${id}`;
    return axiosCustom.delete(url);
  },
};

export default courseApi;
