import { PayloadAction } from '@reduxjs/toolkit';
import drivingLicenseApi from 'api/drivingLicenseApi';
import { ListParams, ResponseModel, DrivingLicenseModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { drivingLicenseActions } from './drivingLicenseSlice';

function* fetchDrivingLicenseList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<DrivingLicenseModel> = yield call(drivingLicenseApi.getAll, action.payload);
    yield put(drivingLicenseActions.fetchDrivingLicenseListSuccess(response));
  } catch (error) {
    yield put(drivingLicenseActions.fetchDrivingLicenseListFailed());
  }
}

export default function* drivingLicenseSaga() {
  yield takeLatest(drivingLicenseActions.fetchDrivingLicenseList.type, fetchDrivingLicenseList);
}
