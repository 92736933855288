import { FormInstance, Modal } from 'antd';
import {
  API_TYPE_CONST,
  COMMON_CONST,
  DATE_FORMAT_YMD,
  DATETIME_FORMAT_YMDHM,
  DATE_FORMAT_MDY,
} from 'constants/commons';
import dayjs from 'dayjs';

export const capitalizeString = (str: string): string => {
  if (!str) return '';

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const getMarkColor = (mark: number): string => {
  if (mark >= 8) return 'green';
  if (mark >= 4) return 'goldenrod';
  return 'red';
};

// Format number
export const formatNumber = (value: number, decimalPlaces: number = 0): string => {
  if (!value) return '0';
  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };

  return new Intl.NumberFormat('en-US', options).format(value);
};

// Format phone number
export const formatPhoneNumber = (phoneNumber: string): string => {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}`;
  }
  return phoneNumber;
};

// Convert format DD/MM/YYYY to YYYY/MM/DD
export const formatDMYToYMD = (date: string): string => {
  // Tách ngày, tháng và năm từ chuỗi
  const [dayStr, monthStr, yearStr] = date.split('/');

  // Tạo một đối tượng Date từ các thành phần ngày, tháng, năm
  const dateObj = new Date(Number(yearStr), Number(monthStr) - 1, Number(dayStr));

  // Lấy các thành phần ngày, tháng, năm từ đối tượng Date
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const dateOfMonth = dateObj.getDate().toString().padStart(2, '0');

  // Tạo chuỗi mới với định dạng 'YYYY/MM/DD'
  const dateNew = `${year}-${month}-${dateOfMonth}`;

  return dateNew;
};

// log when in dev evn
export const logDev = (message: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(message);
  }
};

// format License Plate. Ex: 63H85263 => 63H-852.63
export const formatLicensePlate = (input: string): string => {
  if (input.length === 8) {
    const part1 = input.slice(0, 3); // "63H"
    const part2 = input.slice(3, 6); // "852"
    const part3 = input.slice(6, 8); // "63"

    return `${part1}-${part2}.${part3}`;
  } else {
    // Trả lại chuỗi gốc nếu độ dài không đúng
    return input;
  }
};

// valid error in form from api
export const handleFormError = (errors: any, form: FormInstance<any>) => {
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      switch (error?.detail.attribute) {
        case 'username':
          form.setFields([
            {
              name: 'username',
              errors: [COMMON_CONST.USERNAME_EXIST],
            },
          ]);
          break;
        case 'email':
          form.setFields([
            {
              name: 'email',
              errors: [COMMON_CONST.EMAIL_EXIST],
            },
          ]);
          break;
        case 'license_plate':
          form.setFields([
            {
              name: 'license_plate',
              errors: [COMMON_CONST.LICENSE_PLATE_EXIST],
            },
          ]);
          break;
      }
    });
  }
};

// Kiểm tra data có thay đổi không trước khi update
export const checkDataChanged = (dataUpdate: Record<string, any>, dataSource: Record<string, any>) => {
  let rs = { ...dataUpdate };
  const keys = Object.keys(dataUpdate);

  // So sánh giá trị của từng thuộc tính
  for (const key of keys) {
    let valUpdate = dataUpdate[key];
    let valSource = dataSource[key];

    switch (key) {
      case 'date_of_birth':
        valUpdate = valUpdate ? dayjs(valUpdate).format(DATE_FORMAT_MDY) : '';
        break;
      case 'gpxtl_date':
      case 'gpxtl_expiration_date':
      case 'gcnkd_date':
      case 'gcnkd_expiration_date':
      case 'insurance_expiration_date':
      case 'purchase_date':
        valUpdate = valUpdate ? dayjs(valUpdate).format(DATE_FORMAT_MDY) : '';
        valSource = valSource ? dayjs(valSource).format(DATE_FORMAT_MDY) : '';
        break;
      case 'range_date':
        valUpdate = `${dayjs(valUpdate?.at(0)).format(DATE_FORMAT_MDY)}${dayjs(valUpdate?.at(1)).format(
          DATE_FORMAT_MDY,
        )}`;
        valSource = `${dayjs(valSource?.at(0)).format(DATE_FORMAT_MDY)}${dayjs(valSource?.at(1)).format(
          DATE_FORMAT_MDY,
        )}`;
        break;
      case 'booking_range_time':
      case 'inout_range_time':
        valUpdate = `${dayjs(valUpdate?.at(0)).format(DATETIME_FORMAT_YMDHM)}${dayjs(valUpdate?.at(1)).format(
          DATETIME_FORMAT_YMDHM,
        )}`;
        valSource = `${dayjs(valSource?.at(0)).format(DATETIME_FORMAT_YMDHM)}${dayjs(valSource?.at(1)).format(
          DATETIME_FORMAT_YMDHM,
        )}`;
        break;
      default:
        break;
    }

    // Kiểm tra xem giá trị của thuộc tính có giống nhau không
    if ((!valUpdate && !valSource) || (valUpdate && valSource && valUpdate === valSource)) {
      // Nếu giống nhau, del field
      delete rs[key];
    }
    // else {
    //   logDev(typeof dataUpdate[key]);
    //   logDev(valUpdate);
    //   logDev(valSource);
    // }
  }

  return rs;
};

export const callApiCreateOrUpdate = async (
  record: any,
  dataUpdate: any,
  isUpdate: boolean,
  api: any,
  apiType: string,
) => {
  const dataChanged = isUpdate ? checkDataChanged(record, dataUpdate || {}) : record;
  // Nếu data update chưa thay đổi
  if (isUpdate && Object.keys(dataChanged).length === 0) {
    Modal.warning({
      title: 'Lưu ý',
      content: 'Chưa có dữ liệu nào được thay đổi',
      centered: true,
    });
    return false;
  }

  // Kiểm tra field date_of_birth có thay đổi thì xử lý format
  if (dataChanged.hasOwnProperty('date_of_birth') && dataChanged['date_of_birth']) {
    dataChanged['date_of_birth'] = dayjs(dataChanged.date_of_birth).format(DATE_FORMAT_YMD);
  }

  // Handle for vehicles
  convertDateHasOwnProperty(dataChanged, 'gpxtl_date');
  convertDateHasOwnProperty(dataChanged, 'gpxtl_expiration_date');
  convertDateHasOwnProperty(dataChanged, 'gcnkd_date');
  convertDateHasOwnProperty(dataChanged, 'gcnkd_expiration_date');
  convertDateHasOwnProperty(dataChanged, 'insurance_expiration_date');
  convertDateHasOwnProperty(dataChanged, 'purchase_date');
  // if (dataChanged.hasOwnProperty('purchase_date')) {
  //   dataChanged['purchase_date'] = dayjs(dataChanged.purchase_date).toISOString();
  // }

  // Handle for *_schedules
  if (dataChanged.hasOwnProperty('range_date')) {
    dataChanged['start_date'] = dayjs(dataChanged.range_date?.at(0)).toISOString();
    dataChanged['end_date'] = dayjs(dataChanged.range_date?.at(1)).toISOString();
    delete dataChanged.range_date;
  }

  // Handle for registers_driving_ranges
  if (dataChanged.hasOwnProperty('booking_range_time')) {
    dataChanged['booking_from'] = dayjs(dataChanged.booking_range_time?.at(0)).toISOString();
    dataChanged['booking_to'] = dayjs(dataChanged.booking_range_time?.at(1)).toISOString();
    delete dataChanged.booking_range_time;
  }
  if (dataChanged.hasOwnProperty('inout_range_time')) {
    dataChanged['time_in'] = dayjs(dataChanged.inout_range_time?.at(0)).toISOString();
    dataChanged['time_out'] = dayjs(dataChanged.inout_range_time?.at(1)).toISOString();
    delete dataChanged.inout_range_time;
  }

  let res;
  let req = {
    data: {
      attributes: {
        ...dataChanged,
      },
      type: apiType,
      id: record.id,
    },
  };
  if (!record.date_of_birth) {
    delete req.data.attributes.date_of_birth;
  }
  if (isUpdate) {
    res = await api.update(req);
  } else {
    if (apiType === API_TYPE_CONST.REGISTERS_DRIVING_RANGES) {
      delete req.data.attributes.time_in;
      delete req.data.attributes.time_out;
      delete req.data.attributes.total_hour;
      delete req.data.attributes.amount;
      delete req.data.attributes.is_paid;
    }
    res = await api.add(req);
  }

  return res;
};

const convertDateHasOwnProperty = (dataChanged: any, key: string) => {
  if (dataChanged.hasOwnProperty(key)) {
    dataChanged[key] = dayjs(dataChanged[key]).format(DATE_FORMAT_YMD);
  }
};
