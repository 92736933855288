import { ListParams, RequestModel, ResponseModel, StudentsModel } from 'models';
import axiosCustom from './axiosCustom';

const studentsApi = {
  getAll(params: ListParams): Promise<StudentsModel[]> {
    const url = '/api/dcm/students';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<StudentsModel>> {
    const url = `/api/dcm/students/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<StudentsModel>): Promise<ResponseModel<StudentsModel>> {
    const url = '/api/dcm/students';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<StudentsModel>): Promise<ResponseModel<StudentsModel>> {
    const url = `/api/dcm/students/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<StudentsModel>> {
    const url = `/api/dcm/students/${id}`;
    return axiosCustom.delete(url);
  },
};

export default studentsApi;
