export const ROLE_CONST = {
  ADD_ERROR: 'Thêm vai trò thất bại!',
  ADD_SUCCESS: 'Thêm vai trò thành công!',
  ADD_TITLE: 'Thêm mới vai trò',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa vai trò',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa vai trò',
  DELETE_ERROR: 'Xóa vai trò thất bại!',
  DELETE_SUCCESS: 'Đã xóa vai trò thành công!',
  EDIT_TITLE: 'Cập nhật vai trò',
  NAME: 'Tên vai trò',
  ROLE: 'Vai trò',
  UPDATE_ERROR: 'Cập nhật vai trò thất bại!',
  UPDATE_SUCCESS: 'Cập nhật vai trò thành công!',
};
