import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'models/user.model';

export interface LoginPayload {
  username: string;
  password: string;
  remember: boolean;
  showPassword: boolean;
}

export interface AuthState {
  isLoggedIn: boolean;
  logging?: boolean;
  currentUser?: UserModel;
  error?: string;
}

const initialState: AuthState = {
  isLoggedIn: false,
  logging: false,
  currentUser: undefined,
  error: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.logging = true;
    },
    loginSuccess(state, action: PayloadAction<UserModel>) {
      state.isLoggedIn = true;
      state.logging = false;
      state.currentUser = action.payload;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.logging = false;
      state.error = action.payload;
    },

    logout(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      state.error = '';
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn;
export const selectIsLogging = (state: any) => state.auth.logging;
export const selectError = (state: any) => state.auth.error;
export const selectCurrentUser = (state: any) => state.auth.currentUser;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
