import { PayloadAction } from '@reduxjs/toolkit';
import maintenanceSchedulesApi from 'api/maintenanceSchedulesApi';
import { ListParams, ResponseModel, MaintenanceSchedulesModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { maintenanceSchedulesActions } from './maintenanceSchedulesSlice';

function* fetchMaintenanceSchedulesList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<MaintenanceSchedulesModel> = yield call(
      maintenanceSchedulesApi.getAll,
      action.payload,
    );
    yield put(maintenanceSchedulesActions.fetchMaintenanceSchedulesListSuccess(response));
  } catch (error) {
    yield put(maintenanceSchedulesActions.fetchMaintenanceSchedulesListFailed());
  }
}

export default function* maintenanceSchedulesSaga() {
  yield takeLatest(maintenanceSchedulesActions.fetchMaintenanceSchedulesList.type, fetchMaintenanceSchedulesList);
}
