export const DRIVING_RANGE_TYPE_CONST = {
  ADD_ERROR: 'Thêm loại sân tập thất bại!',
  ADD_SUCCESS: 'Thêm loại sân tập thành công!',
  ADD_TITLE: 'Thêm mới loại sân tập',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa loại sân tập',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa loại sân tập',
  DELETE_ERROR: 'Xóa loại sân tập thất bại!',
  DELETE_SUCCESS: 'Đã xóa loại sân tập thành công!',
  DRIVING_RANGE_TYPE: 'Loại sân tập',
  EDIT_TITLE: 'Cập nhật loại sân tập',
  NAME: 'Tên loại sân tập',
  PRICE: 'Giá (1 giờ)',
  UPDATE_ERROR: 'Cập nhật loại sân tập thất bại!',
  UPDATE_SUCCESS: 'Cập nhật loại sân tập thành công!',
};
