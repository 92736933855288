import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tag,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import registersDrivingRangesApi from 'api/registersDrivingRangesApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { drivingRangeTypeActions, selectDrivingRangeTypeList } from 'features/driving-range-type/drivingRangeTypeSlice';
import { selectStudentsList, studentsActions } from 'features/students/studentsSlice';
import { selectUserList, userActions } from 'features/user/userSlice';
import { selectVehicleList, vehicleActions } from 'features/vehicle/vehicleSlice';
import { IOptions, RegistersDrivingRangesModel, ResponseModel } from 'models';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatLicensePlate, formatNumber } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  APP_DATE_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  AUTHORITIES,
  COMMON_CONST,
  DRIVING_RANGE_TYPE_CONST,
  REGISTERS_DRIVING_RANGES_CONST,
  SITE_NAME,
  STUDENT_CONST,
  TEACHER_CONST,
  VEHICLE_CONST,
} from '../../constants';
import {
  registersDrivingRangesActions,
  selectRegistersDrivingRangesFilter,
  selectRegistersDrivingRangesList,
  selectRegistersDrivingRangesLoading,
  selectRegistersDrivingRangesTotal,
} from './registersDrivingRangesSlice';

const { RangePicker } = DatePicker;

interface IRegistersDrivingRangesData {
  key: React.Key;
  id: string;
  student_id: string;
  student_name: string;
  teacher_id: string;
  teacher_name: string;
  driving_range_id: string;
  driving_range_name: string;
  driving_range_price: number;
  vehicle_id: string;
  vehicle_name: string;
  booking_from: string;
  booking_to: string;
  booking_range_time?: dayjs.Dayjs[];
  deposit: number;
  temp_hour: number;
  time_in: string;
  time_out: string;
  inout_range_time?: dayjs.Dayjs[];
  total_hour: number;
  amount: number;
  is_paid: number;
  note: string;
  created_at: string;
  updated_at: string;
}

export default function RegistersDrivingRanges() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectRegistersDrivingRangesLoading);
  const registersDrivingRangesList = useAppSelector(selectRegistersDrivingRangesList);
  const registersDrivingRangesFilter = useAppSelector(selectRegistersDrivingRangesFilter);
  const registersDrivingRangesTotal = useAppSelector(selectRegistersDrivingRangesTotal);
  const vehicleList = useAppSelector(selectVehicleList);
  const teacherList = useAppSelector(selectUserList);
  const studentList = useAppSelector(selectStudentsList);
  const drivingRangeTypeList = useAppSelector(selectDrivingRangeTypeList);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isToday, setIsToDay] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [drivingRangePrice, setDrivingRangePrice] = useState(0);
  const [vehicleOptions, setVehicleOptions] = useState<IOptions[]>([]);
  const [teacherOptions, setTeacherOptions] = useState<IOptions[]>([]);
  const [studentOptions, setStudentOptions] = useState<IOptions[]>([]);
  const [drivingRangeTypeOptions, setDrivingRangeTypeOptions] = useState<IOptions[]>([]);
  const [dataUpdate, setDataUpdate] = useState<IRegistersDrivingRangesData>();
  const [dataTable, setDataTable] = useState<IRegistersDrivingRangesData[]>();
  const [dataBooked, setDataBooked] = useState<any[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${REGISTERS_DRIVING_RANGES_CONST.REGISTERS_DRIVING_RANGES} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);

    dispatch(
      vehicleActions.fetchVehicleList({
        sort: 'name',
      }),
    );
    dispatch(
      userActions.fetchUserList({
        sort: 'fullname',
        filter: `[{"name":"_role","op":"has","val":{"name":"code","op":"eq","val":"${AUTHORITIES['TEACHER']}"}}]`,
      }),
    );
    dispatch(
      studentsActions.fetchStudentsList({
        sort: 'fullname',
      }),
    );
    dispatch(
      drivingRangeTypeActions.fetchDrivingRangeTypeList({
        sort: 'name',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update vehicle options
  useEffect(() => {
    setVehicleOptions(
      vehicleList.map((vehicle) => {
        return {
          value: vehicle.id || '',
          label: `${vehicle.attributes.name} (${formatLicensePlate(vehicle.attributes.license_plate || '')})` || '',
        };
      }),
    );
  }, [vehicleList]);

  // Update teacher options
  useEffect(() => {
    setTeacherOptions(
      teacherList.map((teacher) => {
        return {
          value: teacher.id || '',
          label: teacher.attributes.fullname || '',
        };
      }),
    );
  }, [teacherList]);

  // Update student options
  useEffect(() => {
    setStudentOptions(
      studentList.map((student) => {
        return {
          value: student.id || '',
          label: student.attributes.fullname || '',
        };
      }),
    );
  }, [studentList]);

  // Update drivingRangeType options
  useEffect(() => {
    setDrivingRangeTypeOptions(
      drivingRangeTypeList.map((drivingRangeType) => {
        return {
          value: drivingRangeType.id || '',
          label: drivingRangeType.attributes.name || '',
        };
      }),
    );
  }, [drivingRangeTypeList]);

  // Config columns in table
  const columns: ColumnsType<IRegistersDrivingRangesData> = [
    {
      title: VEHICLE_CONST.VEHICLE,
      dataIndex: 'vehicle_id',
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.vehicle_name.localeCompare(b.vehicle_name),
        multiple: 14,
      },
      render: (value, record) => record.vehicle_name,
      width: 180,
    },
    {
      title: STUDENT_CONST.STUDENT,
      dataIndex: 'student_id',
      sorter: {
        compare: (a, b) => a.student_name.localeCompare(b.student_name),
        multiple: 13,
      },
      render: (value, record) => record.student_name,
      width: 130,
    },
    {
      title: TEACHER_CONST.TEACHER,
      dataIndex: 'teacher_id',
      sorter: {
        compare: (a, b) => a.teacher_name.localeCompare(b.teacher_name),
        multiple: 12,
      },
      render: (value, record) => record.teacher_name,
      width: 130,
    },
    {
      title: DRIVING_RANGE_TYPE_CONST.DRIVING_RANGE_TYPE,
      dataIndex: 'driving_range_id',
      sorter: {
        compare: (a, b) => a.driving_range_name.localeCompare(b.driving_range_name),
        multiple: 11,
      },
      render: (value, record) => `${record.driving_range_name} (${formatNumber(record.driving_range_price)} đồng/giờ)`,
      width: 180,
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.BOOKING_RANGE_TIME,
      dataIndex: 'booking_range_time',
      sorter: {
        compare: (a, b) => dayjs(a.booking_from).toISOString().localeCompare(dayjs(b.booking_from).toISOString()),
        multiple: 10,
      },
      render: (value, record) => handleDisplayTime(record.booking_from, record.booking_to),
      width: 180,
      align: 'center',
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.TEMP_HOUR,
      dataIndex: 'temp_hour',
      sorter: {
        compare: (a, b) => a.temp_hour - b.temp_hour,
        multiple: 9,
      },
      width: 80,
      align: 'right',
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.DEPOSIT,
      dataIndex: 'deposit',
      sorter: {
        compare: (a, b) => a.deposit - b.deposit,
        multiple: 8,
      },
      render: (value: number) => (value ? formatNumber(value) : ''),
      width: 80,
      align: 'right',
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.INOUT_RANGE_TIME,
      dataIndex: 'inout_range_time',
      sorter: {
        compare: (a, b) => dayjs(a.time_in).toISOString().localeCompare(dayjs(b.time_in).toISOString()),
        multiple: 7,
      },
      render: (value, record) => handleDisplayTime(record.time_in, record.time_out),
      width: 180,
      align: 'center',
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.TOTAL_HOUR,
      dataIndex: 'total_hour',
      sorter: {
        compare: (a, b) => a.total_hour - b.total_hour,
        multiple: 6,
      },
      width: 80,
      align: 'right',
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.AMOUNT,
      dataIndex: 'amount',
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 5,
      },
      render: (value: number) => (value ? formatNumber(value) : ''),
      width: 80,
      align: 'right',
    },
    {
      title: REGISTERS_DRIVING_RANGES_CONST.IS_PAID,
      dataIndex: 'is_paid',
      sorter: {
        compare: (a, b) => a.is_paid - b.is_paid,
        multiple: 4,
      },
      width: 90,
      align: 'center',
      render: (value) => {
        return value ? (
          <CheckCircleOutlined style={{ color: 'green' }} rev={undefined} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} rev={undefined} />
        );
      },
    },
    {
      title: COMMON_CONST.NOTE,
      dataIndex: 'note',
      sorter: {
        compare: (a, b) => a.note.localeCompare(b.note),
        multiple: 3,
      },
      width: 200,
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 120,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 120,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 60,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateRegistersDrivingRanges(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteRegistersDrivingRanges(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  const handleDisplayTime = (timeFrom: string, timeTo: string) => {
    // Tạo hai đối tượng dayjs
    const dateFrom = dayjs(timeFrom);
    const dateTo = dayjs(timeTo);

    // Kiểm tra xem có cùng ngày hay không
    const isSameDay = dateFrom.isSame(dateTo, 'day');

    // Hiển thị theo định dạng mong muốn
    let formattedDate;
    if (isSameDay) {
      formattedDate = (
        <>
          <Tag color={'blue'}>{dateFrom.format(APP_LOCAL_DATE_FORMAT)}</Tag>
          <Tag color={'red'}>{`${dateFrom.format('HH:mm')} - ${dateTo.format('HH:mm')}`}</Tag>
        </>
      );
    } else {
      formattedDate = (
        <>
          <Tag color={'blue'}>{dateFrom.format(APP_DATE_FORMAT)}</Tag>
          <Tag color={'red'}>{dateTo.format(APP_DATE_FORMAT)}</Tag>
        </>
      );
    }

    return formattedDate;
  };

  useEffect(() => {
    refreshRegistersDrivingRangesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(registersDrivingRangesFilter), isToday]);

  // Update dataTable
  useEffect(() => {
    if (
      vehicleOptions.length > 0 &&
      teacherOptions.length > 0 &&
      studentOptions.length > 0 &&
      drivingRangeTypeOptions.length > 0
    ) {
      setDataTable(
        registersDrivingRangesList.map((registersDrivingRanges) => {
          const timeIn = registersDrivingRanges.attributes.time_in || registersDrivingRanges.attributes.booking_from;
          const timeOut = registersDrivingRanges.attributes.time_out || registersDrivingRanges.attributes.booking_to;
          const totalHour = registersDrivingRanges.attributes.total_hour || registersDrivingRanges.attributes.temp_hour;
          return {
            key: registersDrivingRanges.id || '',
            id: registersDrivingRanges.id || '',
            vehicle_id: registersDrivingRanges.attributes.vehicle_id || '',
            vehicle_name:
              vehicleOptions.find((vehicle) => vehicle.value === registersDrivingRanges.attributes.vehicle_id)?.label ||
              '',
            teacher_id: registersDrivingRanges.attributes.teacher_id || '',
            teacher_name:
              teacherOptions.find((teacher) => teacher.value === registersDrivingRanges.attributes.teacher_id)?.label ||
              '',
            student_id: registersDrivingRanges.attributes.student_id || '',
            student_name:
              studentOptions.find((student) => student.value === registersDrivingRanges.attributes.student_id)?.label ||
              '',
            driving_range_id: registersDrivingRanges.attributes.driving_range_id || '',
            driving_range_name:
              drivingRangeTypeOptions.find(
                (drivingRangeType) => drivingRangeType.value === registersDrivingRanges.attributes.driving_range_id,
              )?.label || '',
            driving_range_price: registersDrivingRanges.attributes.driving_range?.price || 0,
            booking_from: registersDrivingRanges.attributes.booking_from || '',
            booking_to: registersDrivingRanges.attributes.booking_to || '',
            booking_range_time: [
              dayjs(registersDrivingRanges.attributes.booking_from),
              dayjs(registersDrivingRanges.attributes.booking_to),
            ],
            deposit: registersDrivingRanges.attributes.deposit || 0,
            temp_hour: registersDrivingRanges.attributes.temp_hour || 0,
            time_in: timeIn || '',
            time_out: timeOut || '',
            inout_range_time: [dayjs(timeIn), dayjs(timeOut)],
            total_hour: totalHour || 0,
            amount: registersDrivingRanges.attributes.amount || 0,
            is_paid: registersDrivingRanges.attributes.is_paid || 0,
            note: registersDrivingRanges.attributes.note || '',
            created_at: registersDrivingRanges.attributes.created_at || '',
            updated_at: registersDrivingRanges.attributes.updated_at || '',
          };
        }),
      );
    }
  }, [registersDrivingRangesList, vehicleOptions, teacherOptions, studentOptions, drivingRangeTypeOptions]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: registersDrivingRangesTotal,
      pageSize: registersDrivingRangesFilter?._limit,
      current: registersDrivingRangesFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registersDrivingRangesTotal, JSON.stringify(registersDrivingRangesFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
      });
      handeAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IRegistersDrivingRangesData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(registersDrivingRangesFilter)
    ) {
      dispatch(
        registersDrivingRangesActions.setFilter({
          ...registersDrivingRangesFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const refreshRegistersDrivingRangesList = () => {
    if (isToday) {
      const timeFrom = dayjs().startOf('day').toISOString();
      const timeTo = dayjs().add(1, 'day').startOf('day').toISOString();
      dispatch(
        registersDrivingRangesActions.fetchRegistersDrivingRangesList({
          'page[number]': registersDrivingRangesFilter?._page,
          'page[size]': registersDrivingRangesFilter?._limit,
          sort: registersDrivingRangesFilter?._sort,
          filter: `[
            {"name":"booking_from","op":"ge","val":"${timeFrom}"},
            {"name":"booking_from","op":"lt","val":"${timeTo}"}
          ]`,
        }),
      );
    } else {
      dispatch(
        registersDrivingRangesActions.fetchRegistersDrivingRangesList({
          'page[number]': registersDrivingRangesFilter?._page,
          'page[size]': registersDrivingRangesFilter?._limit,
          sort: registersDrivingRangesFilter?._sort,
        }),
      );
    }
  };

  const handleAddRegistersDrivingRanges = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      vehicle_id: '',
      vehicle_name: '',
      teacher_id: '',
      teacher_name: '',
      student_id: '',
      student_name: '',
      driving_range_id: '',
      driving_range_name: '',
      driving_range_price: 0,
      booking_from: '',
      booking_to: '',
      booking_range_time: [dayjs().add(1, 'hour').minute(0), dayjs().add(2, 'hour').minute(0)],
      deposit: 0,
      temp_hour: 1,
      time_in: '',
      time_out: '',
      inout_range_time: [dayjs().add(1, 'hour').minute(0), dayjs().add(2, 'hour').minute(0)],
      total_hour: 1,
      amount: 0,
      is_paid: 0,
      note: '',
      created_at: '',
      updated_at: '',
    });
    setDataBooked([]);
  };

  const handleUpdateRegistersDrivingRanges = (record: IRegistersDrivingRangesData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate(record);
    setRemainingAmount(record.amount - record.deposit);
    setDrivingRangePrice(record.driving_range_price);
  };

  const handleDeleteRegistersDrivingRanges = (record: IRegistersDrivingRangesData) => {
    Modal.confirm({
      title: REGISTERS_DRIVING_RANGES_CONST.CONFIRM_DELETE_TITLE,
      content: `${REGISTERS_DRIVING_RANGES_CONST.CONFIRM_DELETE_CONTENT} [${record.vehicle_name} đặt sân từ ${dayjs(
        record.booking_from,
      ).format(APP_DATE_FORMAT)}]?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<RegistersDrivingRangesModel> = {};
        res = await registersDrivingRangesApi.delete(record.id);
        if (res.meta?.message) {
          refreshRegistersDrivingRangesList();
          toast.success(REGISTERS_DRIVING_RANGES_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(
        values,
        dataUpdate,
        isUpdate,
        registersDrivingRangesApi,
        API_TYPE_CONST.REGISTERS_DRIVING_RANGES,
      );
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshRegistersDrivingRangesList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(
        isUpdate ? REGISTERS_DRIVING_RANGES_CONST.UPDATE_SUCCESS : REGISTERS_DRIVING_RANGES_CONST.ADD_SUCCESS,
      );
    } catch (error) {
      setIsLoading(false);
      toast.error(isUpdate ? REGISTERS_DRIVING_RANGES_CONST.UPDATE_ERROR : REGISTERS_DRIVING_RANGES_CONST.ADD_ERROR);
    }
  };

  const onChangeBookingTime = (value: RangeValue<dayjs.Dayjs>) => {
    if (value && value[0] && value[1]) {
      const tempHour = Math.abs(value[0].startOf('minute').diff(value[1].startOf('minute'), 'minute')) / 60;
      form.setFieldValue('temp_hour', tempHour);
    }
  };

  const onChangeInOutTime = (value: RangeValue<dayjs.Dayjs>) => {
    if (value && value[0] && value[1]) {
      const totalHour = Math.abs(value[0].startOf('minute').diff(value[1].startOf('minute'), 'minute')) / 60;
      form.setFieldValue('total_hour', totalHour);
      handeAmount();
    }
  };

  const handeAmount = (price: number = 0) => {
    const tempHour = form.getFieldValue('temp_hour');
    const totalHour = form.getFieldValue('total_hour');
    const deposit = form.getFieldValue('deposit');
    const priceHour = price > 0 ? price : drivingRangePrice;
    const realHour = totalHour >= tempHour ? totalHour : tempHour;
    form.setFieldValue('amount', realHour * priceHour);
    setRemainingAmount(realHour * priceHour - deposit);
  };

  const handleDisplayToday = (e: CheckboxChangeEvent) => {
    setIsToDay(e.target.checked);
  };

  const getBookedList = useCallback(async () => {
    if (isUpdate) {
      return;
    }
    // setDataBooked([]);
    const vehicleId = form.getFieldValue('vehicle_id');
    const bookingRangeTime = form.getFieldValue('booking_range_time');
    if (vehicleId && bookingRangeTime.length > 0) {
      const timeFrom = dayjs(bookingRangeTime.at(0)).startOf('day').toISOString();
      const timeTo = dayjs(bookingRangeTime.at(0)).add(1, 'day').startOf('day').toISOString();
      const req = {
        'filter[vehicle_id]': vehicleId,
        filter: `[
          {"name":"booking_from","op":"ge","val":"${timeFrom}"},
          {"name":"booking_from","op":"lt","val":"${timeTo}"}
        ]`,
        sort: 'booking_from',
      };

      const res = await registersDrivingRangesApi.getAll(req);
      if (Array.isArray(res.data) && res.data?.length > 0) {
        setDataBooked(
          res.data.map((item) => {
            return {
              ...item.attributes,
            };
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={isLoading}>
      <PageHeader
        title={REGISTERS_DRIVING_RANGES_CONST.REGISTERS_DRIVING_RANGES}
        extra={[
          <Checkbox key="cbxToday" defaultChecked={isToday} onChange={(e) => handleDisplayToday(e)}>
            Chỉ hiển thị hôm nay
          </Checkbox>,
          <Button
            key="btnAdd"
            onClick={handleAddRegistersDrivingRanges}
            icon={<PlusCircleOutlined rev={undefined} />}
            style={{ color: 'blue' }}
          >
            {COMMON_CONST.ADD_NEW_BTN}
          </Button>,
        ]}
        style={{ paddingInline: 0 }}
      >
        <Table
          showSorterTooltip={false}
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 3115 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? REGISTERS_DRIVING_RANGES_CONST.EDIT_TITLE : REGISTERS_DRIVING_RANGES_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          width={800}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            scrollToFirstError={true}
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 300px)',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
            )}
            <Row gutter={[16, 8]} align="middle">
              <Col xs={12} lg={12}>
                {/* Xe */}
                <Form.Item
                  name="vehicle_id"
                  label={VEHICLE_CONST.VEHICLE}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={vehicleOptions}
                    onChange={getBookedList}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Loại sân tập */}
                <Form.Item
                  name="driving_range_id"
                  label={DRIVING_RANGE_TYPE_CONST.DRIVING_RANGE_TYPE}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={drivingRangeTypeOptions}
                    onChange={(val) => {
                      const price = drivingRangeTypeList.find((value) => value.id === val)?.attributes.price;
                      setDrivingRangePrice(price || 0);
                      handeAmount(price);
                      // getBookedList();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Học viên */}
                <Form.Item
                  name="student_id"
                  label={STUDENT_CONST.STUDENT}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={studentOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Giáo viên */}
                <Form.Item
                  name="teacher_id"
                  label={TEACHER_CONST.TEACHER}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={teacherOptions}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} lg={12}>
                {/* Thời gian đặt sân */}
                <Form.Item
                  name="booking_range_time"
                  label={REGISTERS_DRIVING_RANGES_CONST.BOOKING_RANGE_TIME}
                  rules={[
                    {
                      required: true,
                      message: COMMON_CONST.REQUIRED_INPUT,
                    },
                  ]}
                >
                  <RangePicker
                    disabled={isUpdate}
                    showTime={{
                      format: 'HH:mm',
                      minuteStep: 30,
                    }}
                    format={APP_DATE_FORMAT}
                    onChange={(values) => {
                      onChangeBookingTime(values);
                      getBookedList();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* Giờ tạm */}
                <Form.Item
                  name="temp_hour"
                  label={REGISTERS_DRIVING_RANGES_CONST.TEMP_HOUR}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <InputNumber disabled={isUpdate} min={0} />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* Đặt cọc */}
                <Form.Item
                  name="deposit"
                  label={REGISTERS_DRIVING_RANGES_CONST.DEPOSIT}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <InputNumber
                    disabled={isUpdate}
                    style={{ width: 150 }}
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                </Form.Item>
              </Col>
              {isUpdate && (
                <>
                  <Col xs={12} lg={12}>
                    {/* Thời gian vào ra */}
                    <Form.Item
                      name="inout_range_time"
                      label={REGISTERS_DRIVING_RANGES_CONST.INOUT_RANGE_TIME}
                      rules={[
                        {
                          required: true,
                          message: COMMON_CONST.REQUIRED_INPUT,
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: 'HH:mm',
                          minuteStep: 15,
                        }}
                        format={APP_DATE_FORMAT}
                        onChange={(values) => onChangeInOutTime(values)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} lg={6}>
                    {/* Giờ tính tiền */}
                    <Form.Item
                      name="total_hour"
                      label={REGISTERS_DRIVING_RANGES_CONST.TOTAL_HOUR}
                      rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                    >
                      <InputNumber min={0} onChange={() => handeAmount()} />
                    </Form.Item>
                  </Col>
                  <Col xs={6} lg={6}>
                    {/* Thành tiền */}
                    <Form.Item name="amount" label={REGISTERS_DRIVING_RANGES_CONST.AMOUNT}>
                      <InputNumber
                        disabled
                        style={{ width: 150 }}
                        min={0}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} lg={12}>
                    <Form.Item>
                      <i>
                        Giá 1 giờ:
                        <span style={{ color: 'red', marginLeft: 8, fontWeight: 'bold' }}>
                          {formatNumber(drivingRangePrice)} đồng
                        </span>
                      </i>
                      <br />
                      <i>
                        Số tiền cần thanh toán thêm:
                        <span style={{ color: 'red', marginLeft: 8, fontWeight: 'bold' }}>
                          {formatNumber(remainingAmount)} đồng
                        </span>
                      </i>
                    </Form.Item>
                  </Col>
                  <Col xs={12} lg={12}>
                    {/* Đã thanh toán */}
                    <Form.Item name="is_paid" valuePropName="checked">
                      <Checkbox>{REGISTERS_DRIVING_RANGES_CONST.IS_PAID}</Checkbox>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col xs={24} lg={24}>
                {/* Ghi chú */}
                <Form.Item name="note" label={COMMON_CONST.NOTE}>
                  <Input />
                </Form.Item>
              </Col>
              {!isUpdate && dataBooked && dataBooked.length > 0 && (
                <Col xs={24} lg={24}>
                  <Card title="Thời gian đã được đặt" size="small" style={{ borderColor: 'red' }}>
                    <List
                      grid={{ gutter: 16, column: 6 }}
                      dataSource={dataBooked}
                      renderItem={(item) => (
                        <List.Item>
                          <Tag color={'red'}>{`${dayjs(item.booking_from).format('HH:mm')} - ${dayjs(
                            item.booking_to,
                          ).format('HH:mm')}`}</Tag>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              )}
            </Row>
          </Form>
        </Modal>
      </PageHeader>
    </Spin>
  );
}
