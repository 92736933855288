export const TEACHER_CONST = {
  ADD_ERROR: 'Thêm giáo viên thất bại!',
  ADD_SUCCESS: 'Thêm giáo viên thành công!',
  ADD_TITLE: 'Thêm mới giáo viên',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa giáo viên',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa giáo viên',
  DELETE_ERROR: 'Xóa giáo viên thất bại!',
  DELETE_SUCCESS: 'Đã xóa giáo viên thành công!',
  EDIT_TITLE: 'Cập nhật giáo viên',
  TEACHER: 'Giáo viên',
  UPDATE_ERROR: 'Cập nhật giáo viên thất bại!',
  UPDATE_SUCCESS: 'Cập nhật giáo viên thành công!',
};
