import {
  CarOutlined,
  CrownOutlined,
  DashboardOutlined,
  IdcardOutlined,
  LineChartOutlined,
  PoweroffOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Modal } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { authActions } from 'features/auth/authSlice';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  COMMON_CONST,
  COURSE_CONST,
  DRIVING_LICENSE_CONST,
  DRIVING_RANGE_TYPE_CONST,
  LOGOUT_CONST,
  MAINTENANCE_SCHEDULES_CONST,
  REGISTRATION_SCHEDULES_CONST,
  ROLE_CONST,
  STUDENT_CONST,
  TEACHER_CONST,
  USER_CONST,
  VEHICLE_CONST,
} from '../../constants';

const { Sider } = Layout;

interface SidebarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export const Sidebar = ({ collapsed, setCollapsed }: SidebarProps) => {
  const [itemSelected] = useState(localStorage.getItem('item_selected') || '');
  const [open, setOpen] = useState(false);

  const [selectedKeys, setSelectedKeys] = useState<string[]>(itemSelected ? [itemSelected] : ['hoc-vien']);
  const [logoutSelected, setLogoutSelected] = useState(false);

  const dispatch = useAppDispatch();

  const infoFeaturesDev = () => {
    Modal.info({
      title: 'Chức năng đang phát triển',
      content: (
        <div>
          <p>Chức năng này sẽ được phát triển trong tương lai.</p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleMenuClick = (e: any) => {
    if (e.key === 'dang-xuat') {
      setOpen(true);
    } else if (['dashboard', 'thong-ke'].includes(e.key)) {
      infoFeaturesDev();
    } else {
      setSelectedKeys([e.key]);
    }
  };

  const handleConfirmLogout = (confirm: boolean) => {
    setOpen(false);

    if (confirm) {
      setSelectedKeys(['dang-xuat']);
      setLogoutSelected(true);
      handleLogout();
    } else {
      setLogoutSelected(false);
    }
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  return (
    <>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={logoutSelected ? ['dang-xuat'] : selectedKeys}
          style={{ marginTop: '20px' }}
          onClick={handleMenuClick}
        >
          <Menu.Item key="dashboard" icon={<DashboardOutlined rev={undefined} />}>
            {/* <Link to="/admin/dashboard">Dashboard</Link> */}
            Dashboard
          </Menu.Item>
          <Menu.Item key="giao-vien" icon={<UserOutlined rev={undefined} />}>
            <Link to="/admin/giao-vien">{TEACHER_CONST.TEACHER}</Link>
          </Menu.Item>
          <Menu.Item key="hoc-vien" icon={<IdcardOutlined rev={undefined} />}>
            <Link to="/admin/hoc-vien">{STUDENT_CONST.STUDENT}</Link>
          </Menu.Item>
          <Menu.Item key="khoa-hoc" icon={<CrownOutlined rev={undefined} />}>
            <Link to="/admin/khoa-hoc">{COURSE_CONST.COURSE}</Link>
          </Menu.Item>
          <Menu.Item key="san-tap-lai" icon={<ScheduleOutlined rev={undefined} />}>
            <Link to="/admin/san-tap-lai">Sân tập lái</Link>
          </Menu.Item>
          <Menu.SubMenu key="quan-ly-xe" icon={<CarOutlined rev={undefined} />} title="Quản lý xe">
            <Menu.Item key="xe">
              <Link to="/admin/xe">{VEHICLE_CONST.VEHICLE}</Link>
            </Menu.Item>
            <Menu.Item key="lich-dang-kiem">
              <Link to="/admin/lich-dang-kiem">{REGISTRATION_SCHEDULES_CONST.REGISTRATION_SCHEDULES}</Link>
            </Menu.Item>
            <Menu.Item key="lich-bao-tri">
              <Link to="/admin/lich-bao-tri">{MAINTENANCE_SCHEDULES_CONST.MAINTENANCE_SCHEDULES}</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="sub1" icon={<SettingOutlined rev={undefined} />} title="Cấu hình">
            <Menu.Item key="nguoi-dung">
              <Link to="/admin/nguoi-dung">{USER_CONST.USER}</Link>
            </Menu.Item>
            <Menu.Item key="vai-tro">
              <Link to="/admin/vai-tro">{ROLE_CONST.ROLE}</Link>
            </Menu.Item>
            <Menu.Item key="hang-xe">
              <Link to="/admin/hang-xe">{DRIVING_LICENSE_CONST.DRIVING_LICENSE}</Link>
            </Menu.Item>
            <Menu.Item key="loai-san-tap">
              <Link to="/admin/loai-san-tap">{DRIVING_RANGE_TYPE_CONST.DRIVING_RANGE_TYPE}</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="thong-ke" icon={<LineChartOutlined rev={undefined} />}>
            {/* <Link to="/admin/thong-ke">Thống kê</Link> */}
            Thống kê
          </Menu.Item>
          <Menu.Item key="dang-xuat" icon={<PoweroffOutlined rev={undefined} />}>
            {LOGOUT_CONST.LOGOUT}
          </Menu.Item>
        </Menu>
      </Sider>
      <Modal
        title={LOGOUT_CONST.LOGOUT}
        open={open}
        onOk={() => handleConfirmLogout(true)}
        onCancel={() => handleConfirmLogout(false)}
        maskClosable={false}
        okText={COMMON_CONST.OK_BTN}
        cancelText={COMMON_CONST.CANCEL_BTN}
      >
        <p>{LOGOUT_CONST.CONFIRM_MSG}</p>
      </Modal>
    </>
  );
};
