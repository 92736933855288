import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, RegistrationSchedulesModel } from 'models';

export interface RegistrationSchedulesState {
  loading: boolean;
  list: ResponseData<RegistrationSchedulesModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: RegistrationSchedulesState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const registrationSchedulesSlice = createSlice({
  name: 'registrationSchedules',
  initialState,
  reducers: {
    fetchRegistrationSchedulesList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchRegistrationSchedulesListSuccess(state, action: PayloadAction<ResponseModel<RegistrationSchedulesModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchRegistrationSchedulesListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const registrationSchedulesActions = registrationSchedulesSlice.actions;

// Selectors
export const selectRegistrationSchedulesList = (state: RootState) => state.registrationSchedules.list;
export const selectRegistrationSchedulesLoading = (state: RootState) => state.registrationSchedules.loading;
export const selectRegistrationSchedulesFilter = (state: RootState) => state.registrationSchedules.filter;
export const selectRegistrationSchedulesTotal = (state: RootState) => state.registrationSchedules.total;

// Reducer
const registrationSchedulesReducer = registrationSchedulesSlice.reducer;
export default registrationSchedulesReducer;
