import { PayloadAction } from '@reduxjs/toolkit';
import roleApi from 'api/roleApi';
import { ListParams, ResponseModel, RoleModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { roleActions } from './roleSlice';

function* fetchRoleList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<RoleModel> = yield call(roleApi.getAll, action.payload);
    yield put(roleActions.fetchRoleListSuccess(response));
  } catch (error) {
    yield put(roleActions.fetchRoleListFailed());
  }
}

export default function* roleSaga() {
  yield takeLatest(roleActions.fetchRoleList.type, fetchRoleList);
}
