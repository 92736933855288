import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, StudentsModel } from 'models';

export interface StudentsState {
  loading: boolean;
  list: ResponseData<StudentsModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: StudentsState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    fetchStudentsList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchStudentsListSuccess(state, action: PayloadAction<ResponseModel<StudentsModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchStudentsListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const studentsActions = studentsSlice.actions;

// Selectors
export const selectStudentsList = (state: RootState) => state.students.list;
export const selectStudentsLoading = (state: RootState) => state.students.loading;
export const selectStudentsFilter = (state: RootState) => state.students.filter;
export const selectStudentsTotal = (state: RootState) => state.students.total;

// Reducer
const studentsReducer = studentsSlice.reducer;
export default studentsReducer;
