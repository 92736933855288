import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, Modal } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { authActions } from 'features/auth/authSlice';
import { UserModel } from 'models/user.model';
import { useEffect, useState } from 'react';
import { COMMON_CONST, LOGOUT_CONST, SITE_NAME } from '../../constants';

const { Header } = Layout;

export function HeaderBar() {
  const [userInfo] = useState(localStorage.getItem('user_info'));
  const [currentUser, setCurrentUser] = useState<UserModel>();
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    }
    // eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key="profile" icon={<UserOutlined rev={undefined} />}>
        <Link to="/admin/profile">Profile</Link>
      </Menu.Item> */}
      <Menu.Item key="logout" icon={<LogoutOutlined rev={undefined} />} onClick={handleOpenDialog}>
        {LOGOUT_CONST.LOGOUT}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header style={{ background: '#5D6D7E', padding: 0 }}>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: 24, color: 'lavender', marginLeft: 16 }}>{SITE_NAME}</span>
          <Dropdown overlay={menu} placement="bottomRight">
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 16px', float: 'right', color: 'white' }}>
              <Avatar size="small" icon={<UserOutlined rev={undefined} />} />
              <span style={{ marginLeft: 8 }}>{currentUser?.fullname}</span>
            </div>
          </Dropdown>
        </div>
      </Header>
      <Modal
        title={LOGOUT_CONST.LOGOUT}
        open={open}
        onOk={handleLogout}
        onCancel={handleCloseDialog}
        maskClosable={false}
        okText={COMMON_CONST.OK_BTN}
        cancelText={COMMON_CONST.CANCEL_BTN}
      >
        <p>{LOGOUT_CONST.CONFIRM_MSG}</p>
      </Modal>
    </>
  );
}
