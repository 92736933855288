import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Spin, Switch, Table, Typography } from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import drivingLicenseApi from 'api/drivingLicenseApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { DrivingLicenseModel, RequestModel, ResponseModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  COMMON_CONST,
  DRIVING_LICENSE_CONST,
  SITE_NAME,
} from '../../constants';
import {
  drivingLicenseActions,
  selectDrivingLicenseFilter,
  selectDrivingLicenseList,
  selectDrivingLicenseLoading,
  selectDrivingLicenseTotal,
} from './drivingLicenseSlice';

const { Title } = Typography;

interface IDrivingLicenseData {
  key: React.Key;
  id: string;
  name: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export default function DrivingLicense() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectDrivingLicenseLoading);
  const drivingLicenseList = useAppSelector(selectDrivingLicenseList);
  const drivingLicenseFilter = useAppSelector(selectDrivingLicenseFilter);
  const drivingLicenseTotal = useAppSelector(selectDrivingLicenseTotal);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<IDrivingLicenseData>();
  const [dataTable, setDataTable] = useState<IDrivingLicenseData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${DRIVING_LICENSE_CONST.DRIVING_LICENSE} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Config columns in table
  const columns: ColumnsType<IDrivingLicenseData> = [
    {
      title: DRIVING_LICENSE_CONST.NAME,
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4,
      },
      width: '20%',
    },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 3,
      },
      width: '10%',
      align: 'center',
      render: (value: any, record: IDrivingLicenseData) => {
        return <Switch checked={value} onChange={(checked) => onChangeStatus(record, checked)} />;
      },
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: '21%',
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: '21%',
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: '8%',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateDrivingLicense(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteDrivingLicense(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshDrivingLicenseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(drivingLicenseFilter)]);

  // Update dataTable
  useEffect(() => {
    setDataTable(
      drivingLicenseList.map((drivingLicense) => {
        return {
          key: drivingLicense.id || '',
          id: drivingLicense.id || '',
          name: drivingLicense.attributes.name || '',
          status: drivingLicense.attributes.status || 0,
          created_at: drivingLicense.attributes.created_at || '',
          updated_at: drivingLicense.attributes.updated_at || '',
        };
      }),
    );
  }, [drivingLicenseList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: drivingLicenseTotal,
      pageSize: drivingLicenseFilter?._limit,
      current: drivingLicenseFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivingLicenseTotal, JSON.stringify(drivingLicenseFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IDrivingLicenseData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(drivingLicenseFilter)
    ) {
      dispatch(
        drivingLicenseActions.setFilter({
          ...drivingLicenseFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const onChangeStatus = async (record: IDrivingLicenseData, checked: boolean) => {
    let res: ResponseModel<DrivingLicenseModel> = {};
    const req: RequestModel<DrivingLicenseModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'driving_licenses',
        id: record.id,
      },
    };
    res = await drivingLicenseApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshDrivingLicenseList();
    }
  };

  const refreshDrivingLicenseList = () => {
    dispatch(
      drivingLicenseActions.fetchDrivingLicenseList({
        'page[number]': drivingLicenseFilter?._page,
        'page[size]': drivingLicenseFilter?._limit,
        sort: drivingLicenseFilter?._sort,
      }),
    );
  };

  const handleAddDrivingLicense = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      name: '',
      status: 1,
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateDrivingLicense = (record: IDrivingLicenseData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate(record);
  };

  const handleDeleteDrivingLicense = (record: IDrivingLicenseData) => {
    Modal.confirm({
      title: DRIVING_LICENSE_CONST.CONFIRM_DELETE_TITLE,
      content: `${DRIVING_LICENSE_CONST.CONFIRM_DELETE_CONTENT} ${record.name}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<DrivingLicenseModel> = {};
        res = await drivingLicenseApi.delete(record.id);
        if (res.meta?.message) {
          refreshDrivingLicenseList();
          toast.success(DRIVING_LICENSE_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(
        values,
        dataUpdate,
        isUpdate,
        drivingLicenseApi,
        API_TYPE_CONST.DRIVING_LICENSES,
      );
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshDrivingLicenseList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? DRIVING_LICENSE_CONST.UPDATE_SUCCESS : DRIVING_LICENSE_CONST.ADD_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      toast.error(isUpdate ? DRIVING_LICENSE_CONST.UPDATE_ERROR : DRIVING_LICENSE_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ float: 'left', margin: '8px 0' }}>
          {DRIVING_LICENSE_CONST.DRIVING_LICENSE}
        </Title>
        <Button
          onClick={handleAddDrivingLicense}
          icon={<PlusCircleOutlined rev={undefined} />}
          style={{ color: 'blue', float: 'right', margin: '8px 0' }}
        >
          {COMMON_CONST.ADD_NEW_BTN}
        </Button>
        <Table
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 1115 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? DRIVING_LICENSE_CONST.EDIT_TITLE : DRIVING_LICENSE_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" label="Id" hidden>
                <Input />
              </Form.Item>
            )}
            {/* Tên hạng xe */}
            <Form.Item
              name="name"
              label={DRIVING_LICENSE_CONST.NAME}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <Input />
            </Form.Item>
            {/* Trạng thái */}
            <Form.Item name="status" label={COMMON_CONST.STATUS} className="collection-create-form_last-form-item">
              <Radio.Group>
                <Radio value={COMMON_CONST.STATUS_ON_VAL}>{COMMON_CONST.STATUS_ON}</Radio>
                <Radio value={COMMON_CONST.STATUS_OFF_VAL}>{COMMON_CONST.STATUS_OFF}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
