import { PayloadAction } from '@reduxjs/toolkit';
import vehicleApi from 'api/vehicleApi';
import { ListParams, ResponseModel, VehicleModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { vehicleActions } from './vehicleSlice';

function* fetchVehicleList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<VehicleModel> = yield call(vehicleApi.getAll, action.payload);
    yield put(vehicleActions.fetchVehicleListSuccess(response));
  } catch (error) {
    yield put(vehicleActions.fetchVehicleListFailed());
  }
}

export default function* vehicleSaga() {
  yield takeLatest(vehicleActions.fetchVehicleList.type, fetchVehicleList);
}
