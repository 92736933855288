import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Radio, Spin, Switch, Table, Typography } from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import drivingRangeTypeApi from 'api/drivingRangeTypeApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { DrivingRangeTypeModel, RequestModel, ResponseModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatNumber } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  COMMON_CONST,
  DRIVING_RANGE_TYPE_CONST,
  SITE_NAME,
} from '../../constants';
import {
  drivingRangeTypeActions,
  selectDrivingRangeTypeFilter,
  selectDrivingRangeTypeList,
  selectDrivingRangeTypeLoading,
  selectDrivingRangeTypeTotal,
} from './drivingRangeTypeSlice';

const { Title } = Typography;

interface IDrivingRangeTypeData {
  key: React.Key;
  id: string;
  name: string;
  price: number;
  status: number;
  created_at: string;
  updated_at: string;
}

export default function DrivingRangeType() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectDrivingRangeTypeLoading);
  const drivingRangeTypeList = useAppSelector(selectDrivingRangeTypeList);
  const drivingRangeTypeFilter = useAppSelector(selectDrivingRangeTypeFilter);
  const drivingRangeTypeTotal = useAppSelector(selectDrivingRangeTypeTotal);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<IDrivingRangeTypeData>();
  const [dataTable, setDataTable] = useState<IDrivingRangeTypeData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${DRIVING_RANGE_TYPE_CONST.DRIVING_RANGE_TYPE} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Config columns in table
  const columns: ColumnsType<IDrivingRangeTypeData> = [
    {
      title: DRIVING_RANGE_TYPE_CONST.NAME,
      dataIndex: 'name',
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4,
      },
      width: 200,
    },
    {
      title: DRIVING_RANGE_TYPE_CONST.PRICE,
      dataIndex: 'price',
      width: 150,
      align: 'right',
      render: (value: number) => formatNumber(value),
    },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 3,
      },
      width: 120,
      align: 'center',
      render: (value: any, record: IDrivingRangeTypeData) => {
        return <Switch checked={value} onChange={(checked) => onChangeStatus(record, checked)} />;
      },
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateDrivingRangeType(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteDrivingRangeType(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshDrivingRangeTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(drivingRangeTypeFilter)]);

  // Update dataTable
  useEffect(() => {
    setDataTable(
      drivingRangeTypeList.map((drivingRangeType) => {
        return {
          key: drivingRangeType.id || '',
          id: drivingRangeType.id || '',
          name: drivingRangeType.attributes.name || '',
          price: drivingRangeType.attributes.price || 0,
          status: drivingRangeType.attributes.status || 0,
          created_at: drivingRangeType.attributes.created_at || '',
          updated_at: drivingRangeType.attributes.updated_at || '',
        };
      }),
    );
  }, [drivingRangeTypeList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: drivingRangeTypeTotal,
      pageSize: drivingRangeTypeFilter?._limit,
      current: drivingRangeTypeFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivingRangeTypeTotal, JSON.stringify(drivingRangeTypeFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IDrivingRangeTypeData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(drivingRangeTypeFilter)
    ) {
      dispatch(
        drivingRangeTypeActions.setFilter({
          ...drivingRangeTypeFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const onChangeStatus = async (record: IDrivingRangeTypeData, checked: boolean) => {
    let res: ResponseModel<DrivingRangeTypeModel> = {};
    const req: RequestModel<DrivingRangeTypeModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'driving_ranges',
        id: record.id,
      },
    };
    res = await drivingRangeTypeApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshDrivingRangeTypeList();
    }
  };

  const refreshDrivingRangeTypeList = () => {
    dispatch(
      drivingRangeTypeActions.fetchDrivingRangeTypeList({
        'page[number]': drivingRangeTypeFilter?._page,
        'page[size]': drivingRangeTypeFilter?._limit,
        sort: drivingRangeTypeFilter?._sort,
      }),
    );
  };

  const handleAddDrivingRangeType = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      name: '',
      price: 0,
      status: 1,
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateDrivingRangeType = (record: IDrivingRangeTypeData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate(record);
  };

  const handleDeleteDrivingRangeType = (record: IDrivingRangeTypeData) => {
    Modal.confirm({
      title: DRIVING_RANGE_TYPE_CONST.CONFIRM_DELETE_TITLE,
      content: `${DRIVING_RANGE_TYPE_CONST.CONFIRM_DELETE_CONTENT} ${record.name}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<DrivingRangeTypeModel> = {};
        res = await drivingRangeTypeApi.delete(record.id);
        if (res.meta?.message) {
          refreshDrivingRangeTypeList();
          toast.success(DRIVING_RANGE_TYPE_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(
        values,
        dataUpdate,
        isUpdate,
        drivingRangeTypeApi,
        API_TYPE_CONST.DRIVING_RANGES,
      );
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshDrivingRangeTypeList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? DRIVING_RANGE_TYPE_CONST.UPDATE_SUCCESS : DRIVING_RANGE_TYPE_CONST.ADD_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      toast.error(isUpdate ? DRIVING_RANGE_TYPE_CONST.UPDATE_ERROR : DRIVING_RANGE_TYPE_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ float: 'left', margin: '8px 0' }}>
          {DRIVING_RANGE_TYPE_CONST.DRIVING_RANGE_TYPE}
        </Title>
        <Button
          onClick={handleAddDrivingRangeType}
          icon={<PlusCircleOutlined rev={undefined} />}
          style={{ color: 'blue', float: 'right', margin: '8px 0' }}
        >
          {COMMON_CONST.ADD_NEW_BTN}
        </Button>
        <Table
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 1115 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? DRIVING_RANGE_TYPE_CONST.EDIT_TITLE : DRIVING_RANGE_TYPE_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" label="Id" hidden>
                <Input />
              </Form.Item>
            )}
            {/* Tên loại sân tập */}
            <Form.Item
              name="name"
              label={DRIVING_RANGE_TYPE_CONST.NAME}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <Input />
            </Form.Item>
            {/* Giá (1 giờ) */}
            <Form.Item
              name="price"
              label={DRIVING_RANGE_TYPE_CONST.PRICE}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <InputNumber
                style={{ width: 150 }}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            {/* Trạng thái */}
            <Form.Item name="status" label={COMMON_CONST.STATUS} className="collection-create-form_last-form-item">
              <Radio.Group>
                <Radio value={COMMON_CONST.STATUS_ON_VAL}>{COMMON_CONST.STATUS_ON}</Radio>
                <Radio value={COMMON_CONST.STATUS_OFF_VAL}>{COMMON_CONST.STATUS_OFF}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
