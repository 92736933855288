import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import userApi from 'api/userApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { roleActions, selectRoleList } from 'features/role/roleSlice';
import { RequestModel, ResponseModel, UserModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatPhoneNumber, handleFormError } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  AUTHORITIES,
  COMMON_CONST,
  DATE_FORMAT_MDY,
  ROLE_CONST,
  SITE_NAME,
  TEACHER_CONST,
} from '../../../constants';
import { selectUserFilter, selectUserList, selectUserLoading, selectUserTotal, userActions } from '../userSlice';

const { Title } = Typography;

interface IUserData {
  key: React.Key;
  id: string;
  email: string;
  username: string;
  password?: string;
  fullname: string;
  id_card: string;
  role_id: string;
  role_code?: string;
  status: number;
  address: string;
  date_of_birth: string;
  phone: string;
  note: string;
  created_at: string;
  updated_at: string;
}

export default function User() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectUserLoading);
  const userList = useAppSelector(selectUserList);
  const roleList = useAppSelector(selectRoleList);
  const userFilter = useAppSelector(selectUserFilter);
  const userTotal = useAppSelector(selectUserTotal);

  const [userInfo] = useState(JSON.parse(localStorage.getItem('user_info') || ''));
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [roleOptions, setRoleOptions] = useState<{ value: any; label: string }[]>([]);
  const [dataUpdate, setDataUpdate] = useState<IUserData>();
  const [dataTable, setDataTable] = useState<IUserData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${TEACHER_CONST.TEACHER} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);
    dispatch(
      roleActions.fetchRoleList({
        sort: 'name',
        filter: `[
          {"name":"code","op":"eq","val":"${AUTHORITIES['TEACHER']}"},
          {"name":"status","op":"eq","val":"${COMMON_CONST.STATUS_ON_VAL}"}
        ]`,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Config columns in table
  const columns: ColumnsType<IUserData> = [
    {
      title: COMMON_CONST.FULLNAME,
      dataIndex: 'fullname',
      sorter: {
        compare: (a, b) => a.fullname.localeCompare(b.fullname),
        multiple: 12,
      },
      width: 200,
      fixed: 'left',
    },
    {
      title: COMMON_CONST.EMAIL,
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 11,
      },
      width: 200,
    },
    {
      title: COMMON_CONST.USERNAME,
      dataIndex: 'username',
      sorter: {
        compare: (a, b) => a.username.localeCompare(b.username),
        multiple: 10,
      },
      width: 180,
    },
    {
      title: ROLE_CONST.ROLE,
      dataIndex: 'role_id',
      sorter: {
        compare: (a, b) => a.role_id.localeCompare(b.role_id),
        multiple: 8,
      },
      align: 'center',
      width: 200,
      render: (value: string, record: IUserData) => {
        let color =
          record.role_code === AUTHORITIES['SUPER_ADMIN']
            ? 'volcano'
            : record.role_code === AUTHORITIES['ADMIN']
            ? 'geekblue'
            : 'green';
        return (
          <Tag color={color} key={value}>
            {roleOptions.find((role) => role.value === value)?.label.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 7,
      },
      width: 120,
      align: 'center',
      render: (value: any, record: IUserData) => {
        return (
          <Switch
            disabled={userInfo.id === record.id}
            checked={value}
            onChange={(checked) => onChangeStatus(record, checked)}
          />
        );
      },
    },
    {
      title: COMMON_CONST.ID_CARD,
      dataIndex: 'id_card',
      sorter: {
        compare: (a, b) => a.id_card.localeCompare(b.id_card),
        multiple: 9,
      },
      align: 'center',
      width: 150,
    },
    {
      title: COMMON_CONST.DATE_OF_BIRTH,
      dataIndex: 'date_of_birth',
      sorter: {
        compare: (a, b) =>
          (a.date_of_birth ? dayjs(a.date_of_birth).toISOString() : '').localeCompare(
            b.date_of_birth ? dayjs(b.date_of_birth).toISOString() : '',
          ),
        multiple: 5,
      },
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 120,
    },
    {
      title: COMMON_CONST.PHONE_NUMBER,
      dataIndex: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 4,
      },
      align: 'center',
      width: 130,
      render: (value: string) => formatPhoneNumber(value),
    },
    {
      title: COMMON_CONST.ADDRESS,
      dataIndex: 'address',
      sorter: {
        compare: (a, b) => a.address.localeCompare(b.address),
        multiple: 6,
      },
      width: 300,
    },
    {
      title: COMMON_CONST.NOTE,
      dataIndex: 'note',
      sorter: {
        compare: (a, b) => a.note.localeCompare(b.note),
        multiple: 3,
      },
      width: 250,
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateUser(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            {userInfo.id !== record.id && (
              <DeleteOutlined
                disabled={true}
                title={COMMON_CONST.DELETE_BTN}
                onClick={() => {
                  handleDeleteUser(record);
                }}
                style={{
                  color: 'red',
                  border: '1px solid',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: 12,
                }}
                rev={undefined}
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(userFilter)]);

  // Update dataTable
  useEffect(() => {
    setDataTable(
      userList.map((user) => {
        return {
          key: user.id || '',
          id: user.id || '',
          email: user.attributes.email || '',
          username: user.attributes.username || '',
          fullname: user.attributes.fullname || '',
          id_card: user.attributes.id_card || '',
          role_id: user.attributes.role_id || '',
          role_code: user.attributes.role?.code || '',
          status: user.attributes.status || 0,
          address: user.attributes.address || '',
          date_of_birth: user.attributes.date_of_birth || '',
          phone: user.attributes.phone || '',
          note: user.attributes.note || '',
          created_at: user.attributes.created_at || '',
          updated_at: user.attributes.updated_at || '',
        };
      }),
    );
  }, [userList]);

  // Update role options
  useEffect(() => {
    setRoleOptions(
      roleList.map((role) => {
        return {
          value: role.id || '',
          label: role.attributes.name || '',
        };
      }),
    );
  }, [roleList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: userTotal,
      pageSize: userFilter?._limit,
      current: userFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTotal, JSON.stringify(userFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
        date_of_birth: dataUpdate.date_of_birth ? dayjs(dataUpdate.date_of_birth) : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IUserData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(userFilter)
    ) {
      dispatch(
        userActions.setFilter({
          ...userFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
          filter: `[{"name":"_role","op":"has","val":{"name":"code","op":"eq","val":"${AUTHORITIES['TEACHER']}"}}]`,
        }),
      );
    }
  };

  const onChangeStatus = async (record: IUserData, checked: boolean) => {
    let res: ResponseModel<UserModel> = {};
    const req: RequestModel<UserModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'users',
        id: record.id,
      },
    };
    res = await userApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshUserList();
    }
  };

  const refreshUserList = () => {
    dispatch(
      userActions.fetchUserList({
        'page[number]': userFilter?._page,
        'page[size]': userFilter?._limit,
        sort: userFilter?._sort,
        filter: `[{"name":"_role","op":"has","val":{"name":"code","op":"eq","val":"${AUTHORITIES['TEACHER']}"}}]`,
      }),
    );
  };

  const handleAddUser = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      email: '',
      username: '',
      fullname: '',
      id_card: '',
      role_id: roleOptions[0].value,
      status: 1,
      address: '',
      date_of_birth: '',
      phone: '',
      note: '',
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateUser = (record: IUserData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate({
      ...record,
      date_of_birth: record.date_of_birth ? dayjs(record.date_of_birth).format(DATE_FORMAT_MDY) : '',
    });
  };

  const handleDeleteUser = (record: IUserData) => {
    Modal.confirm({
      title: TEACHER_CONST.CONFIRM_DELETE_TITLE,
      content: `${TEACHER_CONST.CONFIRM_DELETE_CONTENT} ${record.fullname}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<UserModel> = {};
        res = await userApi.delete(record.id);
        if (res.meta?.message) {
          refreshUserList();
          toast.success(TEACHER_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(values, dataUpdate, isUpdate, userApi, API_TYPE_CONST.USERS);
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshUserList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? TEACHER_CONST.UPDATE_SUCCESS : TEACHER_CONST.ADD_SUCCESS);
    } catch (error: any) {
      handleFormError(error, form);
      setIsLoading(false);
      toast.error(isUpdate ? TEACHER_CONST.UPDATE_ERROR : TEACHER_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ float: 'left', margin: '8px 0' }}>
          {TEACHER_CONST.TEACHER}
        </Title>
        <Button
          onClick={handleAddUser}
          icon={<PlusCircleOutlined rev={undefined} />}
          style={{ color: 'blue', float: 'right', margin: '8px 0' }}
        >
          {COMMON_CONST.ADD_NEW_BTN}
        </Button>
        <Table
          showSorterTooltip={false}
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 2260 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? TEACHER_CONST.EDIT_TITLE : TEACHER_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          width={800}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            scrollToFirstError
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 300px)',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" label="Id" hidden>
                <Input />
              </Form.Item>
            )}
            <Row gutter={[16, 8]} align="middle">
              <Col xs={12} lg={12}>
                {/* Họ và tên */}
                <Form.Item
                  name="fullname"
                  label={COMMON_CONST.FULLNAME}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Email */}
                <Form.Item
                  name="email"
                  label={COMMON_CONST.EMAIL}
                  rules={[{ required: true, type: 'email', message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Tên tài khoản */}
                <Form.Item
                  name="username"
                  label={COMMON_CONST.USERNAME}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {!isUpdate && (
                  <Form.Item
                    name="password"
                    label={COMMON_CONST.PASSWORD}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <Input.Password />
                  </Form.Item>
                )}
              </Col>
              <Col xs={12} lg={12}>
                {/* Vai trò */}
                <Form.Item
                  name="role_id"
                  label={ROLE_CONST.ROLE}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={roleOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Địa chỉ */}
                <Form.Item name="address" label={COMMON_CONST.ADDRESS}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* CMND/CCCD */}
                <Form.Item name="id_card" label={COMMON_CONST.ID_CARD}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* Năm sinh */}
                <Form.Item name="date_of_birth" label={COMMON_CONST.DATE_OF_BIRTH}>
                  <DatePicker format={APP_LOCAL_DATE_FORMAT} />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* SĐT */}
                <Form.Item name="phone" label={COMMON_CONST.PHONE_NUMBER}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24}>
                {/* Ghi chú */}
                <Form.Item name="note" label={COMMON_CONST.NOTE}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Trạng thái */}
                <Form.Item name="status" label={COMMON_CONST.STATUS} className="collection-create-form_last-form-item">
                  <Radio.Group>
                    <Radio disabled={userInfo.id === dataUpdate?.id} value={COMMON_CONST.STATUS_ON_VAL}>
                      {COMMON_CONST.STATUS_ON}
                    </Radio>
                    <Radio disabled={userInfo.id === dataUpdate?.id} value={COMMON_CONST.STATUS_OFF_VAL}>
                      {COMMON_CONST.STATUS_OFF}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
