import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Select, Spin, Switch, Table, Typography } from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import roleApi from 'api/roleApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { RequestModel, ResponseModel, RoleModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  COMMON_CONST,
  ROLE_CODE_OPTIONS,
  ROLE_CONST,
  SITE_NAME,
} from '../../constants';
import { roleActions, selectRoleFilter, selectRoleList, selectRoleLoading, selectRoleTotal } from './roleSlice';

const { Title } = Typography;

interface IRoleData {
  key: React.Key;
  id: string;
  code: string;
  name: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export default function Role() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectRoleLoading);
  const roleList = useAppSelector(selectRoleList);
  const roleFilter = useAppSelector(selectRoleFilter);
  const roleTotal = useAppSelector(selectRoleTotal);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<IRoleData>();
  const [dataTable, setDataTable] = useState<IRoleData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${ROLE_CONST.ROLE} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Config columns in table
  const columns: ColumnsType<IRoleData> = [
    {
      title: COMMON_CONST.CODE,
      dataIndex: 'code',
      sorter: {
        compare: (a, b) => a.code.localeCompare(b.code),
        multiple: 5,
      },
      width: '20%',
    },
    {
      title: ROLE_CONST.NAME,
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4,
      },
      width: '20%',
    },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 3,
      },
      width: '10%',
      align: 'center',
      render: (value: any, record: IRoleData) => {
        return <Switch checked={value} onChange={(checked) => onChangeStatus(record, checked)} />;
      },
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: '21%',
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: '21%',
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: '8%',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateRole(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteRole(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(roleFilter)]);

  // Update dataTable
  useEffect(() => {
    setDataTable(
      roleList.map((role) => {
        return {
          key: role.id || '',
          id: role.id || '',
          code: role.attributes.code || '',
          name: role.attributes.name || '',
          status: role.attributes.status || 0,
          created_at: role.attributes.created_at || '',
          updated_at: role.attributes.updated_at || '',
        };
      }),
    );
  }, [roleList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: roleTotal,
      pageSize: roleFilter?._limit,
      current: roleFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleTotal, JSON.stringify(roleFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IRoleData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(roleFilter)
    ) {
      dispatch(
        roleActions.setFilter({
          ...roleFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const onChangeStatus = async (record: IRoleData, checked: boolean) => {
    let res: ResponseModel<RoleModel> = {};
    const req: RequestModel<RoleModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'roles',
        id: record.id,
      },
    };
    res = await roleApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshRoleList();
    }
  };

  const refreshRoleList = () => {
    dispatch(
      roleActions.fetchRoleList({
        'page[number]': roleFilter?._page,
        'page[size]': roleFilter?._limit,
        sort: roleFilter?._sort,
      }),
    );
  };

  const handleAddRole = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      code: '',
      name: '',
      status: 1,
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateRole = (record: IRoleData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate(record);
  };

  const handleDeleteRole = (record: IRoleData) => {
    Modal.confirm({
      title: ROLE_CONST.CONFIRM_DELETE_TITLE,
      content: `${ROLE_CONST.CONFIRM_DELETE_CONTENT} ${record.name}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<RoleModel> = {};
        res = await roleApi.delete(record.id);
        if (res.meta?.message) {
          refreshRoleList();
          toast.success(ROLE_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(values, dataUpdate, isUpdate, roleApi, API_TYPE_CONST.ROLES);
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshRoleList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? ROLE_CONST.UPDATE_SUCCESS : ROLE_CONST.ADD_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      toast.error(isUpdate ? ROLE_CONST.UPDATE_ERROR : ROLE_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ float: 'left', margin: '8px 0' }}>
          {ROLE_CONST.ROLE}
        </Title>
        <Button
          onClick={handleAddRole}
          icon={<PlusCircleOutlined rev={undefined} />}
          style={{ color: 'blue', float: 'right', margin: '8px 0' }}
        >
          {COMMON_CONST.ADD_NEW_BTN}
        </Button>
        <Table
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          // style={{ maxHeight: 'calc(100vh - 100px)' }}
          scroll={{ y: 680, x: 1115 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? ROLE_CONST.EDIT_TITLE : ROLE_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" label="Id" hidden>
                <Input />
              </Form.Item>
            )}
            {/* Mã */}
            <Form.Item
              name="code"
              label={COMMON_CONST.CODE}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={ROLE_CODE_OPTIONS}
              />
            </Form.Item>
            {/* Tên vai trò */}
            <Form.Item
              name="name"
              label={ROLE_CONST.NAME}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <Input />
            </Form.Item>
            {/* Trạng thái */}
            <Form.Item name="status" label={COMMON_CONST.STATUS} className="collection-create-form_last-form-item">
              <Radio.Group>
                <Radio value={COMMON_CONST.STATUS_ON_VAL}>{COMMON_CONST.STATUS_ON}</Radio>
                <Radio value={COMMON_CONST.STATUS_OFF_VAL}>{COMMON_CONST.STATUS_OFF}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
