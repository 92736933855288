import { ListParams, RequestModel, ResponseModel, DrivingLicenseModel } from 'models';
import axiosCustom from './axiosCustom';

const drivingLicenseApi = {
  getAll(params: ListParams): Promise<DrivingLicenseModel[]> {
    const url = '/api/dcm/driving-licenses';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<DrivingLicenseModel>> {
    const url = `/api/dcm/driving-licenses/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<DrivingLicenseModel>): Promise<ResponseModel<DrivingLicenseModel>> {
    const url = '/api/dcm/driving-licenses';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<DrivingLicenseModel>): Promise<ResponseModel<DrivingLicenseModel>> {
    const url = `/api/dcm/driving-licenses/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<DrivingLicenseModel>> {
    const url = `/api/dcm/driving-licenses/${id}`;
    return axiosCustom.delete(url);
  },
};

export default drivingLicenseApi;
