import { DeleteOutlined, EditOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
} from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import studentsApi from 'api/studentsApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { courseActions, selectCourseList } from 'features/course/courseSlice';
import { drivingLicenseActions, selectDrivingLicenseList } from 'features/driving-license/drivingLicenseSlice';
import { IOptions, ListParams, RequestModel, ResponseModel, StudentsModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatNumber, formatPhoneNumber, handleFormError } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  COMMON_CONST,
  COURSE_CONST,
  DATE_FORMAT_MDY,
  DRIVING_LICENSE_CONST,
  SITE_NAME,
  STUDENT_CONST,
} from '../../constants';
import {
  selectStudentsFilter,
  selectStudentsList,
  selectStudentsLoading,
  selectStudentsTotal,
  studentsActions,
} from './studentsSlice';

const { Title } = Typography;

interface IStudentsData {
  key: React.Key;
  id: string;
  fullname: string;
  email: string | '';
  course_id: string;
  course_name: string;
  course_price?: number;
  amount_paid?: number;
  id_card: string;
  date_of_birth: string;
  phone: string;
  address: string;
  status: number;
  km_completed: number;
  hour_completed: number;
  note: string;
  created_at: string;
  updated_at: string;
}

export default function Students() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectStudentsLoading);
  const studentsList = useAppSelector(selectStudentsList);
  const courseList = useAppSelector(selectCourseList);
  const drivingLicenseList = useAppSelector(selectDrivingLicenseList);
  const studentsFilter = useAppSelector(selectStudentsFilter);
  const studentsTotal = useAppSelector(selectStudentsTotal);

  const [userInfo] = useState(JSON.parse(localStorage.getItem('user_info') || ''));
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<IStudentsData>();
  const [dataTable, setDataTable] = useState<IStudentsData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });
  const [selectCourseValue, setSelectCourseValue] = useState<string | undefined>(undefined);
  const [selectDrivingLicenseValue, setSelectDrivingLicenseValue] = useState<string | undefined>(undefined);
  const [drivingLicenseOptions, setDrivingLicenseOptions] = useState<IOptions[]>([]);
  const [courseOptions, setCourseOptions] = useState<IOptions[]>([]);
  const [courseFilterOptions, setCourseFilterOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    document.title = `${STUDENT_CONST.STUDENT} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);
    dispatch(courseActions.fetchCourseList({ sort: 'name' }));
    dispatch(drivingLicenseActions.fetchDrivingLicenseList({ sort: 'name' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Config columns in table
  const columns: ColumnsType<IStudentsData> = [
    {
      title: COMMON_CONST.FULLNAME,
      dataIndex: 'fullname',
      sorter: {
        compare: (a, b) => a.fullname.localeCompare(b.fullname),
        multiple: 13,
      },
      width: 200,
      fixed: 'left',
    },
    {
      title: COURSE_CONST.COURSE,
      dataIndex: 'course_id',
      sorter: {
        compare: (a, b) => a.course_name.localeCompare(b.course_name),
        multiple: 12,
      },
      width: 300,
      render: (value, record) => record.course_name,
    },
    {
      title: COURSE_CONST.PRICE,
      dataIndex: 'course_price',
      width: 150,
      align: 'right',
      render: (value: number) => formatNumber(value),
    },
    {
      title: STUDENT_CONST.AMOUNT_PAID,
      dataIndex: 'amount_paid',
      width: 150,
      align: 'right',
      render: (value: number) => formatNumber(value),
    },
    {
      title: STUDENT_CONST.KM_COMPLETED,
      dataIndex: 'km_completed',
      sorter: {
        compare: (a, b) => a.km_completed - b.km_completed,
        multiple: 11,
      },
      width: 150,
      align: 'right',
      render: (value: number) => formatNumber(value),
    },
    {
      title: STUDENT_CONST.HOUR_COMPLETED,
      dataIndex: 'hour_completed',
      sorter: {
        compare: (a, b) => a.hour_completed - b.hour_completed,
        multiple: 10,
      },
      width: 150,
      align: 'right',
      render: (value: number) => formatNumber(value),
    },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 9,
      },
      width: 120,
      align: 'center',
      render: (value: any, record: IStudentsData) => {
        return <Switch checked={value} onChange={(checked) => onChangeStatus(record, checked)} />;
      },
    },
    {
      title: COMMON_CONST.EMAIL,
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 8,
      },
      width: 200,
    },
    {
      title: COMMON_CONST.ID_CARD,
      dataIndex: 'id_card',
      sorter: {
        compare: (a, b) => a.id_card.localeCompare(b.id_card),
        multiple: 7,
      },
      align: 'center',
      width: 150,
    },
    {
      title: COMMON_CONST.ADDRESS,
      dataIndex: 'address',
      sorter: {
        compare: (a, b) => a.address.localeCompare(b.address),
        multiple: 6,
      },
      width: 300,
    },
    {
      title: COMMON_CONST.DATE_OF_BIRTH,
      dataIndex: 'date_of_birth',
      sorter: {
        compare: (a, b) => dayjs(a.date_of_birth).toISOString().localeCompare(dayjs(b.date_of_birth).toISOString()),
        multiple: 5,
      },
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 120,
    },
    {
      title: COMMON_CONST.PHONE_NUMBER,
      dataIndex: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 4,
      },
      align: 'center',
      width: 130,
      render: (value: string) => formatPhoneNumber(value),
    },
    {
      title: COMMON_CONST.NOTE,
      dataIndex: 'note',
      sorter: {
        compare: (a, b) => a.note.localeCompare(b.note),
        multiple: 3,
      },
      width: 250,
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateStudents(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            {userInfo.id !== record.id && (
              <DeleteOutlined
                disabled={true}
                title={COMMON_CONST.DELETE_BTN}
                onClick={() => {
                  handleDeleteStudents(record);
                }}
                style={{
                  color: 'red',
                  border: '1px solid',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: 12,
                }}
                rev={undefined}
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshStudentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(studentsFilter)]);

  // Update dataTable
  useEffect(() => {
    if (courseOptions.length > 0) {
      setDataTable(
        studentsList.map((students) => {
          return {
            key: students.id || '',
            id: students.id || '',
            email: students.attributes.email || '',
            fullname: students.attributes.fullname || '',
            id_card: students.attributes.id_card || '',
            course_id: students.attributes.course_id || '',
            course_name: courseOptions.find((course) => course.value === students.attributes.course_id)?.label || '',
            course_price: students.attributes.course?.price || 0,
            amount_paid: students.attributes.amount_paid || 0,
            status: students.attributes.status || 0,
            km_completed: students.attributes.km_completed || 0,
            hour_completed: students.attributes.hour_completed || 0,
            address: students.attributes.address || '',
            date_of_birth: students.attributes.date_of_birth || '',
            phone: students.attributes.phone || '',
            note: students.attributes.note || '',
            created_at: students.attributes.created_at || '',
            updated_at: students.attributes.updated_at || '',
          };
        }),
      );
    }
  }, [studentsList, courseOptions]);

  // Update course options
  useEffect(() => {
    setCourseOptions(
      courseList.map((course) => {
        return {
          value: course.id || '',
          label:
            fullCourseName(
              course.attributes.name,
              course.attributes.driving_license?.name,
              course.attributes.teacher?.fullname,
            ) || '',
        };
      }),
    );
  }, [courseList]);

  // Update driving license options
  useEffect(() => {
    setDrivingLicenseOptions(
      drivingLicenseList.map((drivingLicense) => {
        return {
          value: drivingLicense.id || '',
          label: drivingLicense.attributes.name || '',
        };
      }),
    );
  }, [drivingLicenseList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: studentsTotal,
      pageSize: studentsFilter?._limit,
      current: studentsFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsTotal, JSON.stringify(studentsFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
        date_of_birth: dataUpdate.date_of_birth ? dayjs(dataUpdate.date_of_birth) : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const fullCourseName = (
    courseName: string = '',
    drivingLicenseName: string = '',
    teacherName: string = '',
  ): string => {
    return `${courseName} (${drivingLicenseName} - ${teacherName})`;
  };

  const onChangeTable: TableProps<IStudentsData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        ...studentsFilter,
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(studentsFilter)
    ) {
      dispatch(
        studentsActions.setFilter({
          ...studentsFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const onChangeStatus = async (record: IStudentsData, checked: boolean) => {
    let res: ResponseModel<StudentsModel> = {};
    const req: RequestModel<StudentsModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'students',
        id: record.id,
      },
    };
    res = await studentsApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshStudentsList();
    }
  };

  const refreshStudentsListFilter = (filterDrivingLicense: string = '', filterCourse: string = '') => {
    let params: ListParams = {
      'page[number]': studentsFilter?._page,
      'page[size]': studentsFilter?._limit,
      sort: studentsFilter?._sort,
    };
    if (filterDrivingLicense) {
      params.filter = `[{"name":"_course","op":"has","val":{"name":"driving_license_id","op":"eq","val":"${filterDrivingLicense}"}}]`;
    } else if (filterCourse) {
      params.filter = `[{"name":"_course","op":"has","val":{"name":"id","op":"eq","val":"${filterCourse}"}}]`;
    }
    dispatch(studentsActions.fetchStudentsList(params));
  };

  const refreshStudentsList = () => {
    if (selectCourseValue) {
      refreshStudentsListFilter(undefined, selectCourseValue);
    } else if (selectDrivingLicenseValue) {
      refreshStudentsListFilter(selectDrivingLicenseValue, undefined);
    } else {
      refreshStudentsListFilter();
    }
  };

  const handleAddStudents = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      email: '',
      fullname: '',
      id_card: '',
      course_id: '',
      course_name: '',
      amount_paid: 0,
      status: 1,
      address: '',
      date_of_birth: '',
      phone: '',
      km_completed: 0,
      hour_completed: 0,
      note: '',
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateStudents = (record: IStudentsData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate({
      ...record,
      date_of_birth: record.date_of_birth ? dayjs(record.date_of_birth).format(DATE_FORMAT_MDY) : '',
    });
  };

  const handleDeleteStudents = (record: IStudentsData) => {
    Modal.confirm({
      title: STUDENT_CONST.CONFIRM_DELETE_TITLE,
      content: `${STUDENT_CONST.CONFIRM_DELETE_CONTENT} ${record.fullname}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<StudentsModel> = {};
        res = await studentsApi.delete(record.id);
        if (res.meta?.message) {
          refreshStudentsList();
          toast.success(STUDENT_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    const courseListFilter = courseList.filter(
      (course) => course.attributes.driving_license_id === selectDrivingLicenseValue,
    );
    setCourseFilterOptions(
      courseListFilter.map((course) => {
        return {
          value: course.id || '',
          label:
            fullCourseName(
              course.attributes.name,
              course.attributes.driving_license?.name,
              course.attributes.teacher?.fullname,
            ) || '',
        };
      }),
    );
  }, [courseList, selectDrivingLicenseValue]);

  // set Select Course Value if courseFilterOptions has only 1 option
  useEffect(() => {
    if (courseFilterOptions.length === 1) {
      setSelectCourseValue(courseFilterOptions[0].value);
    }
  }, [courseFilterOptions]);

  const onChangeFilterDrivingLicense = (value: string) => {
    setSelectDrivingLicenseValue(value);
    setSelectCourseValue(undefined);
    refreshStudentsListFilter(value, undefined);
  };

  const onChangeFilterCourse = (value: string) => {
    setSelectCourseValue(value);
    refreshStudentsListFilter(undefined, value);
  };

  const resetFilter = () => {
    setSelectDrivingLicenseValue(undefined);
    setSelectCourseValue(undefined);
    refreshStudentsListFilter();
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(values, dataUpdate, isUpdate, studentsApi, API_TYPE_CONST.STUDENTS);
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshStudentsList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? STUDENT_CONST.UPDATE_SUCCESS : STUDENT_CONST.ADD_SUCCESS);
    } catch (error: any) {
      handleFormError(error, form);
      setIsLoading(false);
      toast.error(isUpdate ? STUDENT_CONST.UPDATE_ERROR : STUDENT_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ margin: '8px 0' }}>
          {STUDENT_CONST.STUDENT}
        </Title>
        <div>
          <Space wrap style={{ float: 'left', margin: '8px 0' }}>
            <Select
              value={selectDrivingLicenseValue}
              style={{ width: 200 }}
              placeholder={DRIVING_LICENSE_CONST.DRIVING_LICENSE}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={drivingLicenseOptions}
              onChange={(value) => onChangeFilterDrivingLicense(value)}
            />
            <Select
              value={selectCourseValue}
              style={{ width: 300 }}
              placeholder={COURSE_CONST.COURSE}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={courseFilterOptions}
              onChange={(value) => onChangeFilterCourse(value)}
            />
            <Button type="primary" onClick={resetFilter} icon={<ReloadOutlined rev={undefined} />}>
              Reset
            </Button>
          </Space>
          <Button
            onClick={handleAddStudents}
            icon={<PlusCircleOutlined rev={undefined} />}
            style={{ color: 'blue', float: 'right', margin: '8px 0' }}
          >
            {COMMON_CONST.ADD_NEW_BTN}
          </Button>
        </div>
        <Table
          showSorterTooltip={false}
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 2260 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? STUDENT_CONST.EDIT_TITLE : STUDENT_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          width={800}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            scrollToFirstError
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 300px)',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" label="Id" hidden>
                <Input />
              </Form.Item>
            )}
            <Row gutter={[16, 8]} align="middle">
              <Col xs={12} lg={12}>
                {/* Họ và tên */}
                <Form.Item
                  name="fullname"
                  label={COMMON_CONST.FULLNAME}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Khóa học */}
                <Form.Item
                  name="course_id"
                  label={COURSE_CONST.COURSE}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={courseOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Email */}
                <Form.Item
                  name="email"
                  label={COMMON_CONST.EMAIL}
                  rules={[{ required: true, type: 'email', message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Địa chỉ */}
                <Form.Item
                  name="address"
                  label={COMMON_CONST.ADDRESS}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* CMND/CCCD */}
                <Form.Item
                  name="id_card"
                  label={COMMON_CONST.ID_CARD}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* Năm sinh */}
                <Form.Item
                  name="date_of_birth"
                  label={COMMON_CONST.DATE_OF_BIRTH}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <DatePicker format={APP_LOCAL_DATE_FORMAT} />
                </Form.Item>
              </Col>

              <Col xs={6} lg={6}>
                {/* SĐT */}
                <Form.Item
                  name="phone"
                  label={COMMON_CONST.PHONE_NUMBER}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Đã thanh toán */}
                <Form.Item
                  name="amount_paid"
                  label={STUDENT_CONST.AMOUNT_PAID}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <InputNumber
                    style={{ width: 150 }}
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* Km đã đi */}
                <Form.Item
                  name="km_completed"
                  label={STUDENT_CONST.KM_COMPLETED}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <InputNumber
                    min={0}
                    max={5000}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                {/* Giờ đã chạy */}
                <Form.Item
                  name="hour_completed"
                  label={STUDENT_CONST.HOUR_COMPLETED}
                  rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                >
                  <InputNumber
                    min={0}
                    max={5000}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24}>
                {/* Ghi chú */}
                <Form.Item name="note" label={COMMON_CONST.NOTE}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                {/* Trạng thái */}
                <Form.Item name="status" label={COMMON_CONST.STATUS} className="collection-create-form_last-form-item">
                  <Radio.Group>
                    <Radio value={COMMON_CONST.STATUS_ON_VAL}>{COMMON_CONST.STATUS_ON}</Radio>
                    <Radio value={COMMON_CONST.STATUS_OFF_VAL}>{COMMON_CONST.STATUS_OFF}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
