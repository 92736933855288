export const REGISTERS_DRIVING_RANGES_CONST = {
  ADD_ERROR: 'Thêm đăng ký sân tập thất bại!',
  ADD_SUCCESS: 'Thêm đăng ký sân tập thành công!',
  ADD_TITLE: 'Thêm mới đăng ký sân tập',
  AMOUNT: 'Thành tiền',
  BOOKING_FROM: 'Đặt sân lúc',
  BOOKING_RANGE_TIME: 'Thời gian đặt sân',
  BOOKING_TO: 'Đặt sân đến',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa đăng ký sân tập',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa đăng ký sân tập',
  DELETE_ERROR: 'Xóa đăng ký sân tập thất bại!',
  DELETE_SUCCESS: 'Đã xóa đăng ký sân tập thành công!',
  DEPOSIT: 'Đặt cọc',
  EDIT_TITLE: 'Cập nhật đăng ký sân tập',
  END_DATE: 'Ngày hết hạn',
  INOUT_RANGE_TIME: 'Thời gian vào ra',
  IS_PAID: 'Đã thanh toán',
  NAME: 'Nội dung đăng kiểm',
  REGISTERS_DRIVING_RANGES: 'Đăng ký sân tập',
  START_DATE: 'Ngày đến hạn',
  TEMP_HOUR: 'Giờ tạm',
  TIME_IN: 'Thời gian vào',
  TIME_OUT: 'Thời gian ra',
  TOTAL_HOUR: 'Giờ thực tế',
  UPDATE_ERROR: 'Cập nhật đăng ký sân tập thất bại!',
  UPDATE_SUCCESS: 'Cập nhật đăng ký sân tập thành công!',
};
