import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Select, Spin, Table, Typography } from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import maintenanceSchedulesApi from 'api/maintenanceSchedulesApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { selectVehicleList, vehicleActions } from 'features/vehicle/vehicleSlice';
import { IOptions, MaintenanceSchedulesModel, ResponseModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatLicensePlate } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  COMMON_CONST,
  MAINTENANCE_SCHEDULES_CONST,
  SITE_NAME,
  VEHICLE_CONST,
} from '../../constants';
import {
  maintenanceSchedulesActions,
  selectMaintenanceSchedulesFilter,
  selectMaintenanceSchedulesList,
  selectMaintenanceSchedulesLoading,
  selectMaintenanceSchedulesTotal,
} from './maintenanceSchedulesSlice';

const { Title } = Typography;
const { RangePicker } = DatePicker;

interface IMaintenanceSchedulesData {
  key: React.Key;
  id: string;
  name: string;
  vehicle_id: string;
  vehicle_name: string;
  start_date: string;
  end_date: string;
  range_date?: dayjs.Dayjs[];
  created_at: string;
  updated_at: string;
}

export default function MaintenanceSchedules() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectMaintenanceSchedulesLoading);
  const maintenanceSchedulesList = useAppSelector(selectMaintenanceSchedulesList);
  const maintenanceSchedulesFilter = useAppSelector(selectMaintenanceSchedulesFilter);
  const maintenanceSchedulesTotal = useAppSelector(selectMaintenanceSchedulesTotal);
  const vehicleList = useAppSelector(selectVehicleList);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState<IOptions[]>([]);
  const [dataUpdate, setDataUpdate] = useState<IMaintenanceSchedulesData>();
  const [dataTable, setDataTable] = useState<IMaintenanceSchedulesData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${MAINTENANCE_SCHEDULES_CONST.MAINTENANCE_SCHEDULES} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);

    dispatch(
      vehicleActions.fetchVehicleList({
        sort: 'name',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update teacher options
  useEffect(() => {
    setVehicleOptions(
      vehicleList.map((vehicle) => {
        return {
          value: vehicle.id || '',
          label: `${vehicle.attributes.name} (${formatLicensePlate(vehicle.attributes.license_plate || '')})` || '',
        };
      }),
    );
  }, [vehicleList]);

  // Config columns in table
  const columns: ColumnsType<IMaintenanceSchedulesData> = [
    {
      title: MAINTENANCE_SCHEDULES_CONST.NAME,
      dataIndex: 'name',
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 6,
      },
      width: 200,
    },
    {
      title: VEHICLE_CONST.VEHICLE,
      dataIndex: 'vehicle_id',
      sorter: {
        compare: (a, b) => a.vehicle_name.localeCompare(b.vehicle_name),
        multiple: 5,
      },
      render: (value, record) => record.vehicle_name,
      width: 200,
    },
    {
      title: MAINTENANCE_SCHEDULES_CONST.START_DATE,
      dataIndex: 'start_date',
      sorter: {
        compare: (a, b) => dayjs(a.start_date).toISOString().localeCompare(dayjs(b.start_date).toISOString()),
        multiple: 4,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: MAINTENANCE_SCHEDULES_CONST.END_DATE,
      dataIndex: 'end_date',
      sorter: {
        compare: (a, b) => dayjs(a.end_date).toISOString().localeCompare(dayjs(b.end_date).toISOString()),
        multiple: 3,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateMaintenanceSchedules(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteMaintenanceSchedules(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshMaintenanceSchedulesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(maintenanceSchedulesFilter)]);

  // Update dataTable
  useEffect(() => {
    if (vehicleOptions.length > 0) {
      setDataTable(
        maintenanceSchedulesList.map((maintenanceSchedules) => {
          return {
            key: maintenanceSchedules.id || '',
            id: maintenanceSchedules.id || '',
            name: maintenanceSchedules.attributes.name || '',
            vehicle_id: maintenanceSchedules.attributes.vehicle_id || '',
            vehicle_name:
              vehicleOptions.find((vehicle) => vehicle.value === maintenanceSchedules.attributes.vehicle_id)?.label ||
              '',
            start_date: maintenanceSchedules.attributes.start_date || '',
            end_date: maintenanceSchedules.attributes.end_date || '',
            range_date: [
              dayjs(maintenanceSchedules.attributes.start_date),
              dayjs(maintenanceSchedules.attributes.end_date),
            ],
            status: maintenanceSchedules.attributes.status || 0,
            created_at: maintenanceSchedules.attributes.created_at || '',
            updated_at: maintenanceSchedules.attributes.updated_at || '',
          };
        }),
      );
    }
  }, [maintenanceSchedulesList, vehicleOptions]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: maintenanceSchedulesTotal,
      pageSize: maintenanceSchedulesFilter?._limit,
      current: maintenanceSchedulesFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceSchedulesTotal, JSON.stringify(maintenanceSchedulesFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IMaintenanceSchedulesData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(maintenanceSchedulesFilter)
    ) {
      dispatch(
        maintenanceSchedulesActions.setFilter({
          ...maintenanceSchedulesFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const refreshMaintenanceSchedulesList = () => {
    dispatch(
      maintenanceSchedulesActions.fetchMaintenanceSchedulesList({
        'page[number]': maintenanceSchedulesFilter?._page,
        'page[size]': maintenanceSchedulesFilter?._limit,
        sort: maintenanceSchedulesFilter?._sort,
      }),
    );
  };

  const handleAddMaintenanceSchedules = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      name: '',
      vehicle_id: '',
      vehicle_name: '',
      start_date: '',
      end_date: '',
      range_date: [dayjs(), dayjs().add(1, 'month')],
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateMaintenanceSchedules = (record: IMaintenanceSchedulesData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate(record);
  };

  const handleDeleteMaintenanceSchedules = (record: IMaintenanceSchedulesData) => {
    Modal.confirm({
      title: MAINTENANCE_SCHEDULES_CONST.CONFIRM_DELETE_TITLE,
      content: `${MAINTENANCE_SCHEDULES_CONST.CONFIRM_DELETE_CONTENT} ${record.name}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<MaintenanceSchedulesModel> = {};
        res = await maintenanceSchedulesApi.delete(record.id);
        if (res.meta?.message) {
          refreshMaintenanceSchedulesList();
          toast.success(MAINTENANCE_SCHEDULES_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(
        values,
        dataUpdate,
        isUpdate,
        maintenanceSchedulesApi,
        API_TYPE_CONST.MAINTENANCE_SCHEDULES,
      );
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshMaintenanceSchedulesList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? MAINTENANCE_SCHEDULES_CONST.UPDATE_SUCCESS : MAINTENANCE_SCHEDULES_CONST.ADD_SUCCESS);
    } catch (error) {
      setIsLoading(false);
      toast.error(isUpdate ? MAINTENANCE_SCHEDULES_CONST.UPDATE_ERROR : MAINTENANCE_SCHEDULES_CONST.ADD_ERROR);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Title level={4} style={{ float: 'left', margin: '8px 0' }}>
          {MAINTENANCE_SCHEDULES_CONST.MAINTENANCE_SCHEDULES}
        </Title>
        <Button
          onClick={handleAddMaintenanceSchedules}
          icon={<PlusCircleOutlined rev={undefined} />}
          style={{ color: 'blue', float: 'right', margin: '8px 0' }}
        >
          {COMMON_CONST.ADD_NEW_BTN}
        </Button>
        <Table
          bordered={true}
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={dataTable}
          pagination={pagi}
          loading={loading}
          onChange={onChangeTable}
          scroll={{ y: 680, x: 1115 }}
        />
        <Modal
          open={isOpenModel}
          title={isUpdate ? MAINTENANCE_SCHEDULES_CONST.EDIT_TITLE : MAINTENANCE_SCHEDULES_CONST.ADD_TITLE}
          maskClosable={false}
          okText={COMMON_CONST.OK_BTN}
          cancelText={COMMON_CONST.CANCEL_BTN}
          onCancel={() => {
            setIsOpenModel(false);
            form.resetFields();
            setDataUpdate(undefined);
          }}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            style={{
              padding: 16,
              borderRadius: 6,
              border: '1px solid blue',
            }}
          >
            {/* Id */}
            {isUpdate && (
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
            )}
            {/* Nội dung bảo trì */}
            <Form.Item
              name="name"
              label={MAINTENANCE_SCHEDULES_CONST.NAME}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
            >
              <Input />
            </Form.Item>
            {/* Xe */}
            <Form.Item
              name="vehicle_id"
              label={VEHICLE_CONST.VEHICLE}
              rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={vehicleOptions}
              />
            </Form.Item>
            {/* Lịch bảo trì */}
            <Form.Item
              name="range_date"
              label={MAINTENANCE_SCHEDULES_CONST.MAINTENANCE_SCHEDULES}
              rules={[
                {
                  required: true,
                  message: COMMON_CONST.REQUIRED_INPUT,
                },
              ]}
            >
              <RangePicker format={APP_LOCAL_DATE_FORMAT} />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
