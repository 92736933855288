import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, MaintenanceSchedulesModel } from 'models';

export interface MaintenanceSchedulesState {
  loading: boolean;
  list: ResponseData<MaintenanceSchedulesModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: MaintenanceSchedulesState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const maintenanceSchedulesSlice = createSlice({
  name: 'maintenanceSchedules',
  initialState,
  reducers: {
    fetchMaintenanceSchedulesList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchMaintenanceSchedulesListSuccess(state, action: PayloadAction<ResponseModel<MaintenanceSchedulesModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchMaintenanceSchedulesListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const maintenanceSchedulesActions = maintenanceSchedulesSlice.actions;

// Selectors
export const selectMaintenanceSchedulesList = (state: RootState) => state.maintenanceSchedules.list;
export const selectMaintenanceSchedulesLoading = (state: RootState) => state.maintenanceSchedules.loading;
export const selectMaintenanceSchedulesFilter = (state: RootState) => state.maintenanceSchedules.filter;
export const selectMaintenanceSchedulesTotal = (state: RootState) => state.maintenanceSchedules.total;

// Reducer
const maintenanceSchedulesReducer = maintenanceSchedulesSlice.reducer;
export default maintenanceSchedulesReducer;
