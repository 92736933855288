import { ListParams, RequestModel, ResponseModel, DrivingRangeTypeModel } from 'models';
import axiosCustom from './axiosCustom';

const drivingRangeTypeApi = {
  getAll(params: ListParams): Promise<DrivingRangeTypeModel[]> {
    const url = '/api/dcm/driving-ranges';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<DrivingRangeTypeModel>> {
    const url = `/api/dcm/driving-ranges/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<DrivingRangeTypeModel>): Promise<ResponseModel<DrivingRangeTypeModel>> {
    const url = '/api/dcm/driving-ranges';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<DrivingRangeTypeModel>): Promise<ResponseModel<DrivingRangeTypeModel>> {
    const url = `/api/dcm/driving-ranges/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<DrivingRangeTypeModel>> {
    const url = `/api/dcm/driving-ranges/${id}`;
    return axiosCustom.delete(url);
  },
};

export default drivingRangeTypeApi;
