import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CookieUtils } from 'utils/cookie-utils';
import { COMMON_CONST, LOGIN_CONST, SITE_NAME } from '../../../constants';
import { authActions, selectError, selectIsLogging } from '../authSlice';
const { Title } = Typography;

interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export default function LoginPage() {
  const [form] = Form.useForm();
  const history = useHistory<{ from: string }>();
  const [rememberStr] = useState<string>(localStorage.getItem('remember') || '');
  const [initialVal] = useState<ILoginForm>({
    username: '',
    password: '',
    remember: false,
  });

  useEffect(() => {
    document.title = `${LOGIN_CONST.LOGIN} | ${SITE_NAME}`;
  }, []);

  useEffect(() => {
    // Handle relogin case after logged
    if (Boolean(CookieUtils.getCookie('SESSION')) && Boolean(localStorage.getItem('user_info'))) {
      history.push('/admin/hoc-vien');
    }

    // Handle remember me
    if (rememberStr) {
      const rememberData = JSON.parse(rememberStr);
      form.setFieldsValue({
        ...initialVal,
        username: rememberData.username,
        password: rememberData.password,
        remember: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLogging);
  const error = useAppSelector(selectError);

  const onFinish = (values: any) => {
    dispatch(authActions.login(values));
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card style={{ width: 500, borderColor: 'blue' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={3}>{SITE_NAME}</Title>
        </div>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={initialVal}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
        >
          <Form.Item<ILoginForm> name="username" rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" rev={undefined} />}
              placeholder={LOGIN_CONST.USER_NAME}
            />
          </Form.Item>

          <Form.Item<ILoginForm> name="password" rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}>
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" rev={undefined} />}
              placeholder={LOGIN_CONST.PASSWORD}
            />
          </Form.Item>

          <Form.Item<ILoginForm> name="remember" valuePropName="checked">
            <Checkbox>{LOGIN_CONST.REMEMBER_ME}</Checkbox>
          </Form.Item>

          {error && <p style={{ color: 'red', margin: 0 }}>{error}</p>}

          <Form.Item>
            <Button disabled={loading} type="primary" htmlType="submit" className="login-form-button" block>
              {loading ? LOGIN_CONST.LOGGING : LOGIN_CONST.LOGIN}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
