import { PayloadAction } from '@reduxjs/toolkit';
import userApi from 'api/userApi';
import { ListParams, ResponseModel, UserModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { userActions } from './userSlice';

function* fetchUserList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<UserModel> = yield call(userApi.getAll, action.payload);
    yield put(userActions.fetchUserListSuccess(response));
  } catch (error) {
    yield put(userActions.fetchUserListFailed());
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.fetchUserList.type, fetchUserList);
}
