export const COMMON_CONST = {
  ADDRESS: 'Địa chỉ',
  CODE: 'Mã',
  CREATED_AT: 'Ngày tạo',
  DATE_OF_BIRTH_EXP: 'Ví dụ: 31/12/2000',
  DATE_OF_BIRTH: 'Ngày sinh',
  EMAIL: 'Email',
  FULLNAME: 'Họ và tên',
  ID_CARD: 'CMND / CCCD',
  NOTE: 'Ghi chú',
  PASSWORD: 'Mật khẩu',
  PHONE_NUMBER: 'SĐT',
  STATUS_OFF_VAL: 0,
  STATUS_OFF: 'Ngừng hoạt động',
  STATUS_ON_VAL: 1,
  STATUS_ON: 'Hoạt động',
  STATUS: 'Trạng thái',
  UPDATED_AT: 'Ngày cập nhật',
  USERNAME: 'Tên tài khoản',
  // Button
  ADD_NEW_BTN: 'Thêm mới',
  CANCEL_BTN: 'Hủy',
  DELETE_BTN: 'Xóa',
  EDIT_BTN: 'Chỉnh sửa',
  OK_BTN: 'OK',
  // Validate
  REQUIRED_INPUT: 'Vui lòng nhập',
  REQUIRED_SELECT: 'Vui lòng chọn',
  USERNAME_EXIST: 'Tên tài khoản đã tồn tại',
  EMAIL_EXIST: 'Email đã tồn tại',
  LICENSE_PLATE_EXIST: 'Biển số đã tồn tại',
};

// Api Type
export const API_TYPE_CONST = {
  COURSES: 'courses',
  DRIVING_LICENSES: 'driving_licenses',
  DRIVING_RANGES: 'driving_ranges',
  MAINTENANCE_SCHEDULES: 'maintenance_schedules',
  ME: 'me',
  PAYMENTS_HISTORY: 'payments_history',
  REGISTERS_DRIVING_RANGES: 'registers_driving_ranges',
  REGISTRATION_SCHEDULES: 'registration_schedules',
  ROLES: 'roles',
  STUDENTS: 'students',
  USERS: 'users',
  VEHICLES: 'vehicles',
};

// Logout
export const LOGOUT_CONST = {
  LOGOUT: 'Đăng xuất',
  CONFIRM_MSG: 'Bạn muốn đăng xuất khỏi tài khoản này?',
};

export const SITE_NAME = 'QUẢN LÝ TRƯỜNG LÁI SÔNG TIỀN';
export const SITE_NAME_OS = 'HỆ THỐNG QUẢN LÝ TRƯỜNG LÁI SÔNG TIỀN';

export const AUTHORITIES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  TEACHER: 'teacher',
};

export const ROLE_CODE_OPTIONS = Object.keys(AUTHORITIES).map((item) => ({
  value: AUTHORITIES[item],
  label: item,
}));

export const MODULES = {
  DASHBOARD: 'DASHBOARD',
  STATUS: 'STATUS',
  STATISTIC: 'STATISTIC',
  HISTORY: 'HISTORY',
  CENTER: 'CENTER',
  CAMERA: 'CAMERA',
  CENTER_ADD: 'CENTER_ADD',
  CENTER_EDIT: 'CENTER_EDIT',
  CENTER_DELETE: 'CENTER_DELETE',
  AREA: 'AREA',
  AREA_ADD: 'AREA_ADD',
  AREA_EDIT: 'AREA_EDIT',
  AREA_DELETE: 'AREA_DELETE',
  STATION: 'STATION',
  STATION_ADD: 'STATION_ADD',
  STATION_EDIT: 'STATION_EDIT',
  STATION_DELETE: 'STATION_DELETE',
  STATION_CONFIG: 'STATION_CONFIG',
  STATION_ALARM: 'STATION_ALARM',
  STATION_STATISTIC: 'STATION_STATISTIC',
  ROLE: 'ROLE',
  ROLE_ADD: 'ROLE_ADD',
  ROLE_EDIT: 'ROLE_EDIT',
  ROLE_DELETE: 'ROLE_DELETE',
  MODULES: 'MODULES',
  MODULES_ADD: 'MODULES_ADD',
  MODULES_EDIT: 'MODULES_EDIT',
  MODULES_DELETE: 'MODULES_DELETE',
  USER: 'USER',
  USER_ADD: 'USER_ADD',
  USER_EDIT: 'USER_EDIT',
  USER_DELETE: 'USER_DELETE',
  ALERT_EVENTS: 'ALERT_EVENTS',
  ALERT_EVENTS_UPDATE: 'ALERT_EVENTS_UPDATE',
};

export const MODULES_OPTIONS = Object.keys(MODULES).map((item) => ({
  value: MODULES[item],
  label: item,
}));

// Format datetime
export const DATE_FORMAT_MDY = 'MM/DD/YYYY';
export const DATETIME_FORMAT_YMDHM = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_YMD = 'YYYY-MM-DD';
export const APP_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_DATETIME_LOCAL_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm:ss Z';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
