import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Spin,
  Switch,
  Table,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import vehicleApi from 'api/vehicleApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import * as ExcelJS from 'exceljs';
import { drivingLicenseActions } from 'features/driving-license/drivingLicenseSlice';
import { saveAs } from 'file-saver';
import { RequestModel, ResponseModel, VehicleModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApiCreateOrUpdate, formatLicensePlate, handleFormError, logDev } from 'utils';
import {
  API_TYPE_CONST,
  APP_DATETIME_LOCAL_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  COMMON_CONST,
  DATE_FORMAT_MDY,
  SITE_NAME,
  VEHICLE_CONST,
} from '../../constants';
import {
  selectVehicleFilter,
  selectVehicleList,
  selectVehicleLoading,
  selectVehicleTotal,
  vehicleActions,
} from './vehicleSlice';

interface IVehicleData {
  key: React.Key;
  id: string;
  name: string;
  brand: string;
  license_plate: string;
  driving_license_id: string;
  driving_license_name: string;
  purchase_date: string;
  status: number;
  test_vehicle_number: number;
  register_number: number;
  vehicle_type: string;
  manufacturer: string;
  frame_number: string;
  gpxtl_license: string;
  gpxtl_date: string;
  gcnkd_date: string;
  engine_number: string;
  gpxtl_number: string;
  manufacture_year: number;
  color: string;
  gpxtl_expiration_date: string;
  gcnkd_expiration_date: string;
  insurance_expiration_date: string;
  is_auxiliary_brake: number;
  is_effective: number;
  is_insurance: number;
  note: string;
  created_at: string;
  updated_at: string;
}

export default function Vehicle() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectVehicleLoading);
  const vehicleList = useAppSelector(selectVehicleList);
  const vehicleFilter = useAppSelector(selectVehicleFilter);
  const vehicleTotal = useAppSelector(selectVehicleTotal);
  // const drivingLicenseList = useAppSelector(selectDrivingLicenseList);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  // const [drivingLicenseOptions, setDrivingLicenseOptions] = useState<IOptions[]>([]);
  const [dataUpdate, setDataUpdate] = useState<IVehicleData>();
  const [rowSelection] = useState<TableRowSelection<IVehicleData> | undefined>({});
  const [dataSelected, setDataSelected] = useState<IVehicleData[]>([]);
  const [dataTable, setDataTable] = useState<IVehicleData[]>();
  const [pagi, setPagi] = useState<TablePaginationConfig>({
    showTotal: (total: number, range: [number, number]) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    showSizeChanger: true,
  });

  useEffect(() => {
    document.title = `${VEHICLE_CONST.VEHICLE} | ${SITE_NAME}`;
    localStorage.setItem('item_selected', location.pathname.split('/')[2]);

    dispatch(
      drivingLicenseActions.fetchDrivingLicenseList({
        sort: 'name',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update driving license options
  // useEffect(() => {
  //   setDrivingLicenseOptions(
  //     drivingLicenseList.map((drivingLicense) => {
  //       return {
  //         value: drivingLicense.id || '',
  //         label: drivingLicense.attributes.name || '',
  //       };
  //     }),
  //   );
  // }, [drivingLicenseList]);

  // Config columns in table
  const hiddenColumn = [
    'register_number',
    'vehicle_type',
    'manufacturer',
    'frame_number',
    'gpxtl_license',
    'engine_number',
    'gpxtl_number',
    'is_auxiliary_brake',
    'is_effective',
    'is_insurance',
  ];
  const columns: ColumnsType<IVehicleData> = [
    {
      title: VEHICLE_CONST.LICENSE_PLATE,
      dataIndex: 'license_plate',
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.license_plate.localeCompare(b.license_plate),
        multiple: 26,
      },
      render: (value) => formatLicensePlate(value),
      width: 150,
    },
    {
      title: VEHICLE_CONST.TEST_VEHICLE_NUMBER,
      dataIndex: 'test_vehicle_number',
      sorter: {
        compare: (a, b) => a.test_vehicle_number - b.test_vehicle_number,
        multiple: 25,
      },
      width: 150,
      align: 'center',
      render: (value) => value || '',
    },
    {
      title: VEHICLE_CONST.REGISTER_NUMBER,
      dataIndex: 'register_number',
      sorter: {
        compare: (a, b) => a.register_number - b.register_number,
        multiple: 24,
      },
      width: 150,
      align: 'center',
      render: (value) => value || '',
    },
    {
      title: VEHICLE_CONST.BRAND,
      dataIndex: 'brand',
      sorter: {
        compare: (a, b) => a.brand.localeCompare(b.brand),
        multiple: 23,
      },
      width: 200,
      ellipsis: true,
    },
    {
      title: VEHICLE_CONST.NAME,
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 22,
      },
      width: 200,
      ellipsis: true,
    },
    {
      title: VEHICLE_CONST.MANUFACTURER,
      dataIndex: 'manufacturer',
      sorter: {
        compare: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
        multiple: 21,
      },
      width: 200,
      ellipsis: true,
    },
    {
      title: VEHICLE_CONST.FRAME_NUMBER,
      dataIndex: 'frame_number',
      sorter: {
        compare: (a, b) => a.frame_number.localeCompare(b.frame_number),
        multiple: 20,
      },
      width: 200,
    },
    {
      title: VEHICLE_CONST.GPXTL_LICENSE,
      dataIndex: 'gpxtl_license',
      sorter: {
        compare: (a, b) => a.gpxtl_license.localeCompare(b.gpxtl_license),
        multiple: 19,
      },
      width: 200,
      ellipsis: true,
    },
    {
      title: VEHICLE_CONST.GPXTL_DATE,
      dataIndex: 'gpxtl_date',
      sorter: {
        compare: (a, b) => dayjs(a.gpxtl_date).toISOString().localeCompare(dayjs(b.gpxtl_date).toISOString()),
        multiple: 18,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      align: 'center',
      width: 200,
    },
    {
      title: VEHICLE_CONST.GCNKD_DATE,
      dataIndex: 'gcnkd_date',
      sorter: {
        compare: (a, b) => dayjs(a.gcnkd_date).toISOString().localeCompare(dayjs(b.gcnkd_date).toISOString()),
        multiple: 17,
      },
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 200,
    },
    {
      title: VEHICLE_CONST.ENGINE_NUMBER,
      dataIndex: 'engine_number',
      sorter: {
        compare: (a, b) => a.engine_number.localeCompare(b.engine_number),
        multiple: 16,
      },
      width: 200,
    },
    {
      title: VEHICLE_CONST.GPXTL_NUMBER,
      dataIndex: 'gpxtl_number',
      sorter: {
        compare: (a, b) => a.gpxtl_number.localeCompare(b.gpxtl_number),
        multiple: 15,
      },
      width: 200,
    },
    {
      title: VEHICLE_CONST.MANUFACTURE_YEAR,
      dataIndex: 'manufacture_year',
      sorter: {
        compare: (a, b) => a.manufacture_year - b.manufacture_year,
        multiple: 14,
      },
      width: 150,
      align: 'center',
      render: (value) => value || '',
    },
    {
      title: VEHICLE_CONST.COLOR,
      dataIndex: 'color',
      sorter: {
        compare: (a, b) => a.color.localeCompare(b.color),
        multiple: 13,
      },
      width: 200,
    },
    {
      title: VEHICLE_CONST.GPXTL_EXPIRATION_DATE,
      dataIndex: 'gpxtl_expiration_date',
      sorter: {
        compare: (a, b) =>
          dayjs(a.gpxtl_expiration_date).toISOString().localeCompare(dayjs(b.gpxtl_expiration_date).toISOString()),
        multiple: 12,
      },
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 200,
    },
    {
      title: VEHICLE_CONST.GCNKD_EXPIRATION_DATE,
      dataIndex: 'gcnkd_expiration_date',
      sorter: {
        compare: (a, b) =>
          dayjs(a.gcnkd_expiration_date).toISOString().localeCompare(dayjs(b.gcnkd_expiration_date).toISOString()),
        multiple: 11,
      },
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 200,
    },
    {
      title: VEHICLE_CONST.INSURANCE_EXPIRATION_DATE,
      dataIndex: 'insurance_expiration_date',
      sorter: {
        compare: (a, b) =>
          dayjs(a.insurance_expiration_date)
            .toISOString()
            .localeCompare(dayjs(b.insurance_expiration_date).toISOString()),
        multiple: 10,
      },
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
      width: 200,
    },
    {
      title: COMMON_CONST.NOTE,
      dataIndex: 'note',
      sorter: {
        compare: (a, b) => a.note.localeCompare(b.note),
        multiple: 9,
      },
      width: 200,
    },
    {
      title: VEHICLE_CONST.IS_AUXILIARY_BRAKE,
      dataIndex: 'is_auxiliary_brake',
      sorter: {
        compare: (a, b) => a.is_auxiliary_brake - b.is_auxiliary_brake,
        multiple: 8,
      },
      width: 200,
      align: 'center',
      render: (value) => {
        return value ? (
          <CheckCircleOutlined style={{ color: 'green' }} rev={undefined} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} rev={undefined} />
        );
      },
    },
    {
      title: VEHICLE_CONST.IS_EFFECTIVE,
      dataIndex: 'is_effective',
      sorter: {
        compare: (a, b) => a.is_effective - b.is_effective,
        multiple: 7,
      },
      width: 150,
      align: 'center',
      render: (value) => {
        return value ? (
          <CheckCircleOutlined style={{ color: 'green' }} rev={undefined} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} rev={undefined} />
        );
      },
    },
    {
      title: VEHICLE_CONST.IS_INSURANCE,
      dataIndex: 'is_insurance',
      sorter: {
        compare: (a, b) => a.is_insurance - b.is_insurance,
        multiple: 6,
      },
      width: 150,
      align: 'center',
      render: (value) => {
        return value ? (
          <CheckCircleOutlined style={{ color: 'green' }} rev={undefined} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} rev={undefined} />
        );
      },
    },
    // {
    //   title: DRIVING_LICENSE_CONST.DRIVING_LICENSE,
    //   dataIndex: 'driving_license_id',
    //   sorter: {
    //     compare: (a, b) => a.driving_license_name.localeCompare(b.driving_license_name),
    //     multiple: 5,
    //   },
    //   render: (value, record) => record.driving_license_name,
    //   align: 'center',
    //   width: 120,
    // },
    // {
    //   title: VEHICLE_CONST.PURCHASE_DATE,
    //   dataIndex: 'purchase_date',
    //   sorter: {
    //     compare: (a, b) => dayjs(a.purchase_date).toISOString().localeCompare(dayjs(b.purchase_date).toISOString()),
    //     multiple: 4,
    //   },
    //   align: 'center',
    //   render: (value: string) => (value ? dayjs(value).format(APP_LOCAL_DATE_FORMAT) : ''),
    //   width: 200,
    // },
    {
      title: COMMON_CONST.STATUS,
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 3,
      },
      width: 120,
      align: 'center',
      render: (value: any, record: IVehicleData) => {
        return <Switch checked={value} onChange={(checked) => onChangeStatus(record, checked)} />;
      },
    },
    {
      title: COMMON_CONST.CREATED_AT,
      dataIndex: 'created_at',
      sorter: {
        compare: (a, b) => dayjs(a.created_at).toISOString().localeCompare(dayjs(b.created_at).toISOString()),
        multiple: 2,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      title: COMMON_CONST.UPDATED_AT,
      dataIndex: 'updated_at',
      sorter: {
        compare: (a, b) => dayjs(a.updated_at).toISOString().localeCompare(dayjs(b.updated_at).toISOString()),
        multiple: 1,
      },
      render: (value: string) => (value ? dayjs(value).format(APP_DATETIME_LOCAL_FORMAT) : ''),
      width: 200,
      align: 'center',
    },
    {
      key: 'actions',
      title: '',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        return (
          <>
            <EditOutlined
              title={COMMON_CONST.EDIT_BTN}
              onClick={() => {
                handleUpdateVehicle(record);
              }}
              style={{ color: 'blue', border: '1px solid', borderRadius: '3px', padding: '3px' }}
              rev={undefined}
            />
            <DeleteOutlined
              title={COMMON_CONST.DELETE_BTN}
              onClick={() => {
                handleDeleteVehicle(record);
              }}
              style={{ color: 'red', border: '1px solid', borderRadius: '3px', padding: '3px', marginLeft: 12 }}
              rev={undefined}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refreshVehicleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(vehicleFilter)]);

  // Update dataTable
  useEffect(() => {
    setDataTable(
      vehicleList.map((vehicle) => {
        return {
          key: vehicle.id || '',
          id: vehicle.id || '',
          name: vehicle.attributes.name || '',
          license_plate: vehicle.attributes.license_plate || '',
          driving_license_id: vehicle.attributes.driving_license_id || '',
          driving_license_name: vehicle.attributes.driving_license?.name || '',
          brand: vehicle.attributes.brand || '',
          purchase_date: vehicle.attributes.purchase_date || '',
          status: vehicle.attributes.status || 0,
          test_vehicle_number: vehicle.attributes.test_vehicle_number || 0,
          register_number: vehicle.attributes.register_number || 0,
          vehicle_type: vehicle.attributes.vehicle_type || '',
          manufacturer: vehicle.attributes.manufacturer || '',
          frame_number: vehicle.attributes.frame_number || '',
          gpxtl_license: vehicle.attributes.gpxtl_license || '',
          gpxtl_date: vehicle.attributes.gpxtl_date || '',
          gcnkd_date: vehicle.attributes.gcnkd_date || '',
          engine_number: vehicle.attributes.engine_number || '',
          gpxtl_number: vehicle.attributes.gpxtl_number || '',
          manufacture_year: vehicle.attributes.manufacture_year || 0,
          color: vehicle.attributes.color || '',
          gpxtl_expiration_date: vehicle.attributes.gpxtl_expiration_date || '',
          gcnkd_expiration_date: vehicle.attributes.gcnkd_expiration_date || '',
          insurance_expiration_date: vehicle.attributes.insurance_expiration_date || '',
          is_auxiliary_brake: vehicle.attributes.is_auxiliary_brake || 0,
          is_effective: vehicle.attributes.is_effective || 0,
          is_insurance: vehicle.attributes.is_insurance || 0,
          note: vehicle.attributes.note || '',
          created_at: vehicle.attributes.created_at || '',
          updated_at: vehicle.attributes.updated_at || '',
        };
      }),
    );
  }, [vehicleList]);

  // Update total item
  useEffect(() => {
    setPagi({
      ...pagi,
      total: vehicleTotal,
      pageSize: vehicleFilter?._limit,
      current: vehicleFilter?._page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTotal, JSON.stringify(vehicleFilter)]);

  // Set data modal
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
        gpxtl_date: dataUpdate.gpxtl_date ? dayjs(dataUpdate.gpxtl_date) : '',
        gpxtl_expiration_date: dataUpdate.gpxtl_expiration_date ? dayjs(dataUpdate.gpxtl_expiration_date) : '',
        gcnkd_date: dataUpdate.gcnkd_date ? dayjs(dataUpdate.gcnkd_date) : '',
        gcnkd_expiration_date: dataUpdate.gcnkd_expiration_date ? dayjs(dataUpdate.gcnkd_expiration_date) : '',
        insurance_expiration_date: dataUpdate.insurance_expiration_date
          ? dayjs(dataUpdate.insurance_expiration_date)
          : '',
        purchase_date: dataUpdate.purchase_date ? dayjs(dataUpdate.purchase_date) : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate]);

  const onChangeTable: TableProps<IVehicleData>['onChange'] = (pagination, filters, sorter, extra) => {
    if (
      JSON.stringify({
        _page: pagination.current,
        _limit: pagination.pageSize,
      }) !== JSON.stringify(vehicleFilter)
    ) {
      dispatch(
        vehicleActions.setFilter({
          ...vehicleFilter,
          _page: pagination.current,
          _limit: pagination.pageSize,
        }),
      );
    }
  };

  const onChangeStatus = async (record: IVehicleData, checked: boolean) => {
    let res: ResponseModel<VehicleModel> = {};
    const req: RequestModel<VehicleModel> = {
      data: {
        attributes: {
          status:
            record.status === COMMON_CONST.STATUS_ON_VAL ? COMMON_CONST.STATUS_OFF_VAL : COMMON_CONST.STATUS_ON_VAL,
        },
        type: 'vehicles',
        id: record.id,
      },
    };
    res = await vehicleApi.update(req);
    if (!Array.isArray(res.data) && res.data?.id) {
      refreshVehicleList();
    }
  };

  const refreshVehicleList = () => {
    dispatch(
      vehicleActions.fetchVehicleList({
        'page[number]': vehicleFilter?._page,
        'page[size]': vehicleFilter?._limit,
        sort: vehicleFilter?._sort,
      }),
    );
  };

  const handleAddVehicle = () => {
    setIsOpenModel(true);
    setIsUpdate(false);
    setDataUpdate({
      key: '',
      id: '',
      name: '',
      driving_license_id: '',
      driving_license_name: '',
      license_plate: '',
      brand: '',
      purchase_date: '',
      test_vehicle_number: 0,
      register_number: 0,
      vehicle_type: 'Ô tô con tập lái',
      manufacturer: '',
      frame_number: '',
      gpxtl_license: 'Sở GTVT tỉnh Tiền Giang',
      gpxtl_date: '',
      gcnkd_date: '',
      engine_number: '',
      gpxtl_number: '',
      manufacture_year: dayjs().get('year'),
      color: '',
      gpxtl_expiration_date: '',
      gcnkd_expiration_date: '',
      insurance_expiration_date: '',
      is_auxiliary_brake: 0,
      is_effective: 0,
      is_insurance: 0,
      note: '',
      status: 1,
      created_at: '',
      updated_at: '',
    });
  };

  const handleUpdateVehicle = (record: IVehicleData) => {
    setIsOpenModel(true);
    setIsUpdate(true);
    setDataUpdate({
      ...record,
      gpxtl_date: record.gpxtl_date ? dayjs(record.gpxtl_date).format(DATE_FORMAT_MDY) : '',
      gpxtl_expiration_date: record.gpxtl_expiration_date
        ? dayjs(record.gpxtl_expiration_date).format(DATE_FORMAT_MDY)
        : '',
      gcnkd_date: record.gcnkd_date ? dayjs(record.gcnkd_date).format(DATE_FORMAT_MDY) : '',
      gcnkd_expiration_date: record.gcnkd_expiration_date
        ? dayjs(record.gcnkd_expiration_date).format(DATE_FORMAT_MDY)
        : '',
      insurance_expiration_date: record.insurance_expiration_date
        ? dayjs(record.insurance_expiration_date).format(DATE_FORMAT_MDY)
        : '',
      purchase_date: record.purchase_date ? dayjs(record.purchase_date).format(DATE_FORMAT_MDY) : '',
    });
  };

  const handleDeleteVehicle = (record: IVehicleData) => {
    Modal.confirm({
      title: VEHICLE_CONST.CONFIRM_DELETE_TITLE,
      content: `${VEHICLE_CONST.CONFIRM_DELETE_CONTENT} ${record.name} ${formatLicensePlate(record.license_plate)}?`,
      okText: COMMON_CONST.OK_BTN,
      cancelText: COMMON_CONST.CANCEL_BTN,
      okType: 'danger',
      onOk: async () => {
        setIsLoading(true);
        let res: ResponseModel<VehicleModel> = {};
        res = await vehicleApi.delete(record.id);
        if (res.meta?.message) {
          refreshVehicleList();
          toast.success(VEHICLE_CONST.DELETE_SUCCESS);
        }
        setIsLoading(false);
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const rs = await callApiCreateOrUpdate(values, dataUpdate, isUpdate, vehicleApi, API_TYPE_CONST.VEHICLES);
      if (!rs) {
        setIsLoading(false);
        return;
      }
      refreshVehicleList();
      setIsOpenModel(false);
      setIsLoading(false);
      form.resetFields();
      toast.success(isUpdate ? VEHICLE_CONST.UPDATE_SUCCESS : VEHICLE_CONST.ADD_SUCCESS);
    } catch (error) {
      handleFormError(error, form);
      setIsLoading(false);
      toast.error(isUpdate ? VEHICLE_CONST.UPDATE_ERROR : VEHICLE_CONST.ADD_ERROR);
    }
  };

  const handleRowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: IVehicleData[]) => {
    setDataSelected(selectedRows || []);
    logDev(selectedRows);
  };

  const handleExportExcel = async () => {
    logDev(dataSelected);
    if (dataSelected.length === 0) {
      Modal.warning({
        title: 'Tải xuống Excel',
        content: 'Vui lòng chọn dữ liệu để tải xuống',
        centered: true,
      });
      return;
    }

    // Lấy đường dẫn tuyệt đối đến file template
    const templatePath = `${process.env.PUBLIC_URL}/template/vehicle_template.xlsx`;

    // Tải file template từ server hoặc public folder
    const response = await fetch(templatePath);
    const arrayBuffer = await response.arrayBuffer();

    const data = new Uint8Array(arrayBuffer);
    const templateWorkbook = new ExcelJS.Workbook();
    await templateWorkbook.xlsx.load(data);

    // Lấy sheet cần thao tác
    const sheet = templateWorkbook.getWorksheet(1);

    // Chèn dữ liệu vào sheet
    if (sheet) {
      dataSelected.forEach((row, rowIndex) => {
        setCellValue(sheet, `A${rowIndex + 2}`, formatLicensePlate(row.license_plate));
        setCellValue(sheet, `B${rowIndex + 2}`, row.register_number);
        setCellValue(sheet, `C${rowIndex + 2}`, row.brand);
        setCellValue(sheet, `D${rowIndex + 2}`, row.name);
        setCellValue(sheet, `E${rowIndex + 2}`, row.vehicle_type);
        setCellValue(sheet, `F${rowIndex + 2}`, row.manufacturer);
        setCellValue(sheet, `G${rowIndex + 2}`, row.frame_number);
        setCellValue(sheet, `H${rowIndex + 2}`, row.gpxtl_license);
        setCellValue(sheet, `I${rowIndex + 2}`, row.gpxtl_date ? dayjs(row.gpxtl_date).format(DATE_FORMAT_MDY) : '');
        setCellValue(sheet, `J${rowIndex + 2}`, row.gcnkd_date ? dayjs(row.gcnkd_date).format(DATE_FORMAT_MDY) : '');
        setCellValue(sheet, `K${rowIndex + 2}`, row.engine_number);
        setCellValue(sheet, `L${rowIndex + 2}`, row.gpxtl_number);
        setCellValue(sheet, `M${rowIndex + 2}`, row.manufacture_year);
        setCellValue(sheet, `N${rowIndex + 2}`, row.color);
        setCellValue(
          sheet,
          `O${rowIndex + 2}`,
          row.gpxtl_expiration_date ? dayjs(row.gpxtl_expiration_date).format(DATE_FORMAT_MDY) : '',
        );
        setCellValue(
          sheet,
          `P${rowIndex + 2}`,
          row.gcnkd_expiration_date ? dayjs(row.gcnkd_expiration_date).format(DATE_FORMAT_MDY) : '',
        );
        setCellValue(sheet, `Q${rowIndex + 2}`, row.note);
        setCellValue(sheet, `R${rowIndex + 2}`, row.is_auxiliary_brake ? 'x' : '');
        setCellValue(sheet, `S${rowIndex + 2}`, row.is_effective ? 'x' : '');
        setCellValue(sheet, `T${rowIndex + 2}`, row.is_insurance ? 'x' : '');
      });
    }

    // Tạo blob từ workbook
    const excelBuffer = await templateWorkbook.xlsx.writeBuffer();

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([excelBuffer], { type: fileType });
    saveAs(blob, `Danh_Sach_Xe_${dayjs().format('DDMMYYHHmmss')}.xlsx`);
  };

  const setCellValue = (sheet: ExcelJS.Worksheet, cellName: string, data: any) => {
    const cellReference = sheet.getCell(cellName);
    cellReference.value = data;
  };

  const handleShowAll = (e: CheckboxChangeEvent) => {
    setIsShowAll(e.target.checked);
  };

  const disabledGpxtlDate = (current: dayjs.Dayjs) => {
    const date: dayjs.Dayjs = form.getFieldValue('gpxtl_date');
    return current && (current.isBefore(date, 'day') || current.isSame(date, 'day'));
  };

  const disabledGpxtlExpiDate = (current: dayjs.Dayjs) => {
    const date: dayjs.Dayjs = form.getFieldValue('gpxtl_expiration_date');
    return current && (current.isAfter(date, 'day') || current.isSame(date, 'day'));
  };

  const disabledGcnkdDate = (current: dayjs.Dayjs) => {
    const date: dayjs.Dayjs = form.getFieldValue('gcnkd_date');
    return current && (current.isBefore(date, 'day') || current.isSame(date, 'day'));
  };

  const disabledGcnkdExpiDate = (current: dayjs.Dayjs) => {
    const date: dayjs.Dayjs = form.getFieldValue('gcnkd_expiration_date');
    return current && (current.isAfter(date, 'day') || current.isSame(date, 'day'));
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          title={VEHICLE_CONST.VEHICLE}
          extra={[
            <Checkbox key="cbxShowAll" defaultChecked={isShowAll} onChange={(e) => handleShowAll(e)}>
              Hiển thị tất cả thông tin
            </Checkbox>,
            <Button
              key="btnExportExcel"
              onClick={handleExportExcel}
              icon={<DownloadOutlined rev={undefined} />}
              style={{ color: 'green' }}
            >
              {'Tải xuống Excel'}
            </Button>,
            <Button
              key="btnAdd"
              onClick={handleAddVehicle}
              icon={<PlusCircleOutlined rev={undefined} />}
              style={{ color: 'blue' }}
            >
              {COMMON_CONST.ADD_NEW_BTN}
            </Button>,
          ]}
          style={{ paddingInline: 0 }}
        >
          <Table
            showSorterTooltip={false}
            rowSelection={{ ...rowSelection, onChange: handleRowSelectionChange }}
            bordered={true}
            columns={isShowAll ? columns : columns.filter((column: any) => !hiddenColumn.includes(column.dataIndex))}
            rowKey={(record) => record.key}
            dataSource={dataTable}
            pagination={pagi}
            loading={loading}
            onChange={onChangeTable}
            scroll={{ y: 680, x: 3000 }}
          />
          <Modal
            open={isOpenModel}
            title={isUpdate ? VEHICLE_CONST.EDIT_TITLE : VEHICLE_CONST.ADD_TITLE}
            maskClosable={false}
            okText={COMMON_CONST.OK_BTN}
            cancelText={COMMON_CONST.CANCEL_BTN}
            width={800}
            onCancel={() => {
              setIsOpenModel(false);
              form.resetFields();
              setDataUpdate(undefined);
            }}
            onOk={() => {
              form.submit();
            }}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              scrollToFirstError={true}
              onFinish={onFinish}
              autoComplete="on"
              style={{
                padding: 16,
                borderRadius: 6,
                border: '1px solid blue',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 300px)',
              }}
            >
              {/* Id */}
              {isUpdate && (
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
              )}
              <Row gutter={[16, 8]} align="middle">
                <Col xs={6} lg={6}>
                  {/* Biển số  */}
                  <Form.Item
                    name="license_plate"
                    label={VEHICLE_CONST.LICENSE_PLATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <Input placeholder="Ví dụ: 63A12345" />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Số xe sát hạch */}
                  <Form.Item
                    name="test_vehicle_number"
                    label={VEHICLE_CONST.TEST_VEHICLE_NUMBER}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <InputNumber
                      style={{ width: 170 }}
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Số đăng ký */}
                  <Form.Item
                    name="register_number"
                    label={VEHICLE_CONST.REGISTER_NUMBER}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <InputNumber
                      style={{ width: 170 }}
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Nhãn hiệu */}
                  <Form.Item
                    name="brand"
                    label={VEHICLE_CONST.BRAND}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <Input placeholder="Ví dụ: TOYOTA" />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  {/* Mác xe */}
                  <Form.Item
                    name="name"
                    label={VEHICLE_CONST.NAME}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <Input placeholder="Ví dụ: VIOS E MT" />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  {/* Loại xe */}
                  <Form.Item name="vehicle_type" label={VEHICLE_CONST.VEHICLE_TYPE}>
                    <Input placeholder="Ví dụ: Ô tô con tập lái" />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  {/* Hãng xe */}
                  <Form.Item name="manufacturer" label={VEHICLE_CONST.MANUFACTURER}>
                    <Input placeholder="Ví dụ: TOYOTA" />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  {/* Số khung */}
                  <Form.Item name="frame_number" label={VEHICLE_CONST.FRAME_NUMBER}>
                    <Input placeholder="Ví dụ: RL4B28F38P5177381" />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  {/* Cơ quan cấp GPXTL */}
                  <Form.Item name="gpxtl_license" label={VEHICLE_CONST.GPXTL_LICENSE}>
                    <Input placeholder="Ví dụ: Sở GTVT tỉnh Tiền Giang" />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Ngày cấp GPXTL */}
                  <Form.Item
                    name="gpxtl_date"
                    label={VEHICLE_CONST.GPXTL_DATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <DatePicker format={APP_LOCAL_DATE_FORMAT} disabledDate={disabledGpxtlExpiDate} />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Ngày hết hạn GPXTL */}
                  <Form.Item
                    name="gpxtl_expiration_date"
                    label={VEHICLE_CONST.GPXTL_EXPIRATION_DATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <DatePicker format={APP_LOCAL_DATE_FORMAT} disabledDate={disabledGpxtlDate} />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Ngày cấp GCNKĐ */}
                  <Form.Item
                    name="gcnkd_date"
                    label={VEHICLE_CONST.GCNKD_DATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <DatePicker format={APP_LOCAL_DATE_FORMAT} disabledDate={disabledGcnkdExpiDate} />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Ngày hết hạn GCNKĐ */}
                  <Form.Item
                    name="gcnkd_expiration_date"
                    label={VEHICLE_CONST.GCNKD_EXPIRATION_DATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <DatePicker format={APP_LOCAL_DATE_FORMAT} disabledDate={disabledGcnkdDate} />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  {/* Số động cơ */}
                  <Form.Item name="engine_number" label={VEHICLE_CONST.ENGINE_NUMBER}>
                    <Input placeholder="Ví dụ: 2NRY113399" />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Số GP xe TL */}
                  <Form.Item name="gpxtl_number" label={VEHICLE_CONST.GPXTL_NUMBER}>
                    <Input placeholder="Ví dụ: 255/GPXTL - SGTVT" />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Năm sản xuất */}
                  <Form.Item
                    name="manufacture_year"
                    label={VEHICLE_CONST.MANUFACTURE_YEAR}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <InputNumber style={{ width: 170 }} min={0} />
                  </Form.Item>
                </Col>
                <Col xs={6} lg={6}>
                  {/* Màu xe */}
                  <Form.Item name="color" label={VEHICLE_CONST.COLOR}>
                    <Input placeholder="Ví dụ: Trắng" />
                  </Form.Item>
                </Col>

                <Col xs={6} lg={6}>
                  {/* Ngày hết hạn BH */}
                  <Form.Item
                    name="insurance_expiration_date"
                    label={VEHICLE_CONST.INSURANCE_EXPIRATION_DATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <DatePicker format={APP_LOCAL_DATE_FORMAT} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  {/* Ghi chú */}
                  <Form.Item name="note" label={COMMON_CONST.NOTE}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  {/* Có hệ thống phanh phụ */}
                  <Form.Item name="is_auxiliary_brake" valuePropName="checked">
                    <Checkbox>{VEHICLE_CONST.IS_AUXILIARY_BRAKE}</Checkbox>
                  </Form.Item>
                  {/* Có hiệu lực */}
                  <Form.Item name="is_effective" valuePropName="checked">
                    <Checkbox>{VEHICLE_CONST.IS_EFFECTIVE}</Checkbox>
                  </Form.Item>
                  {/* Có bảo hiểm */}
                  <Form.Item name="is_insurance" valuePropName="checked">
                    <Checkbox>{VEHICLE_CONST.IS_INSURANCE}</Checkbox>
                  </Form.Item>
                </Col>
                {/* Hạng xe */}
                {/* <Col xs={6} lg={6}>
                  <Form.Item
                    name="driving_license_id"
                    label={DRIVING_LICENSE_CONST.DRIVING_LICENSE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_SELECT }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={drivingLicenseOptions}
                    />
                  </Form.Item>
                </Col> */}
                {/* Ngày nhập */}
                {/* <Col xs={6} lg={6}>
                  <Form.Item
                    name="purchase_date"
                    label={VEHICLE_CONST.PURCHASE_DATE}
                    rules={[{ required: true, message: COMMON_CONST.REQUIRED_INPUT }]}
                  >
                    <DatePicker format={APP_LOCAL_DATE_FORMAT} />
                  </Form.Item>
                </Col> */}
                <Col xs={12} lg={12}>
                  {/* Trạng thái */}
                  <Form.Item
                    name="status"
                    label={COMMON_CONST.STATUS}
                    className="collection-create-form_last-form-item"
                  >
                    <Radio.Group>
                      <Radio value={COMMON_CONST.STATUS_ON_VAL}>{COMMON_CONST.STATUS_ON}</Radio>
                      <Radio value={COMMON_CONST.STATUS_OFF_VAL}>{COMMON_CONST.STATUS_OFF}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </PageHeader>
      </Spin>
    </div>
  );
}
