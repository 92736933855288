import React, { useState } from 'react';
// import type { MenuProps } from 'antd';
import { Layout } from 'antd';
import { HeaderBar, NotFound, Sidebar } from 'components/Common';
import { Route, Switch } from 'react-router-dom';
import Dashboard from 'features/dashboard';
import StudentFeature from 'features/student';
import Role from 'features/role';
import DrivingLicense from 'features/driving-license';
import User from 'features/user/pages/UserPage';
import DrivingRangeType from 'features/driving-range-type';
import Course from 'features/course';
import Students from 'features/students';
import TeacherPage from 'features/user/pages/TeacherPage';
import Vehicle from 'features/vehicle';
import MaintenanceSchedules from 'features/maintenance-schedules';
import RegistrationSchedules from 'features/registration-schedules';
import RegistersDrivingRanges from 'features/registers-driving-ranges';

const { Content } = Layout;

// type MenuItem = Required<MenuProps>['items'][number];

// function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   } as MenuItem;
// }

// const items: MenuItem[] = [
//   getItem('Option 1', '1', <PieChartOutlined rev={undefined} />),
//   getItem('Option 2', '2', <DesktopOutlined rev={undefined} />),
//   getItem('User', 'sub1', <UserOutlined rev={undefined} />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined rev={undefined} />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined rev={undefined} />),
// ];

export function AdminLayout() {
  const [collapsed, setCollapsed] = useState(false);
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        {/* <Header style={{ padding: 0, background: colorBgContainer }}>Header</Header> */}
        <HeaderBar></HeaderBar>
        <Content style={{ margin: '0 16px' }}>
          <Switch>
            <Route path="/admin/dashboard">
              <Dashboard />
            </Route>

            <Route path="/admin/students">
              <StudentFeature />
            </Route>

            <Route path="/admin/vai-tro">
              <Role />
            </Route>

            <Route path="/admin/hang-xe">
              <DrivingLicense />
            </Route>

            <Route path="/admin/nguoi-dung">
              <User />
            </Route>

            <Route path="/admin/loai-san-tap">
              <DrivingRangeType />
            </Route>

            <Route path="/admin/khoa-hoc">
              <Course />
            </Route>

            <Route path="/admin/hoc-vien">
              <Students />
            </Route>

            <Route path="/admin/giao-vien">
              <TeacherPage />
            </Route>

            <Route path="/admin/xe">
              <Vehicle />
            </Route>

            <Route path="/admin/lich-bao-tri">
              <MaintenanceSchedules />
            </Route>

            <Route path="/admin/lich-dang-kiem">
              <RegistrationSchedules />
            </Route>

            <Route path="/admin/san-tap-lai">
              <RegistersDrivingRanges />
            </Route>

            <Route path="/admin/*">
              <NotFound />
            </Route>
          </Switch>
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}
      </Layout>
    </Layout>
  );
}
