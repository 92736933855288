import * as React from 'react';
import { Redirect, RouteProps, Route } from 'react-router-dom';
import { CookieUtils } from 'utils/cookie-utils';

export function PrivateRoute(props: RouteProps) {
  // Check if user is logged in
  // If yes, show route
  // Otherwise, redirect to login page
  const isLoggedIn = Boolean(localStorage.getItem('user_info'));
  const sessionToken = CookieUtils.getCookie('SESSION');
  if (!isLoggedIn || !sessionToken) return <Redirect to="/dang-nhap" />;

  return <Route {...props} />;
}
