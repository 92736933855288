export const COURSE_CONST = {
  ADD_ERROR: 'Thêm khóa học thất bại!',
  ADD_SUCCESS: 'Thêm khóa học thành công!',
  ADD_TITLE: 'Thêm mới khóa học',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa khóa học',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa khóa học',
  COURSE: 'Khóa học',
  DELETE_ERROR: 'Xóa khóa học thất bại!',
  DELETE_SUCCESS: 'Đã xóa khóa học thành công!',
  EDIT_TITLE: 'Cập nhật khóa học',
  NAME: 'Tên khóa học',
  PRICE: 'Học phí',
  UPDATE_ERROR: 'Cập nhật khóa học thất bại!',
  UPDATE_SUCCESS: 'Cập nhật khóa học thành công!',
};
