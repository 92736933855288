export const MAINTENANCE_SCHEDULES_CONST = {
  ADD_ERROR: 'Thêm lịch bảo trì thất bại!',
  ADD_SUCCESS: 'Thêm lịch bảo trì thành công!',
  ADD_TITLE: 'Thêm mới lịch bảo trì',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa lịch bảo trì',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa lịch bảo trì',
  MAINTENANCE_SCHEDULES: 'Lịch bảo trì',
  DELETE_ERROR: 'Xóa lịch bảo trì thất bại!',
  DELETE_SUCCESS: 'Đã xóa lịch bảo trì thành công!',
  EDIT_TITLE: 'Cập nhật lịch bảo trì',
  NAME: 'Nội dung bảo trì',
  UPDATE_ERROR: 'Cập nhật lịch bảo trì thất bại!',
  UPDATE_SUCCESS: 'Cập nhật lịch bảo trì thành công!',
  START_DATE: 'Ngày đến hạn',
  END_DATE: 'Ngày hết hạn',
};
