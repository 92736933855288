import { ListParams, RequestModel, ResponseModel, VehicleModel } from 'models';
import axiosCustom from './axiosCustom';

const vehicleApi = {
  getAll(params: ListParams): Promise<VehicleModel[]> {
    const url = '/api/dcm/vehicles';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<VehicleModel>> {
    const url = `/api/dcm/vehicles/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<VehicleModel>): Promise<ResponseModel<VehicleModel>> {
    const url = '/api/dcm/vehicles';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<VehicleModel>): Promise<ResponseModel<VehicleModel>> {
    const url = `/api/dcm/vehicles/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<VehicleModel>> {
    const url = `/api/dcm/vehicles/${id}`;
    return axiosCustom.delete(url);
  },
};

export default vehicleApi;
