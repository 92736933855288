import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, CourseModel } from 'models';

export interface CourseState {
  loading: boolean;
  list: ResponseData<CourseModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: CourseState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    fetchCourseList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchCourseListSuccess(state, action: PayloadAction<ResponseModel<CourseModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchCourseListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const courseActions = courseSlice.actions;

// Selectors
export const selectCourseList = (state: RootState) => state.course.list;
export const selectCourseLoading = (state: RootState) => state.course.loading;
export const selectCourseFilter = (state: RootState) => state.course.filter;
export const selectCourseTotal = (state: RootState) => state.course.total;

// Reducer
const courseReducer = courseSlice.reducer;
export default courseReducer;
