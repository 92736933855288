import { PayloadAction } from '@reduxjs/toolkit';
import courseApi from 'api/courseApi';
import { ListParams, ResponseModel, CourseModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { courseActions } from './courseSlice';

function* fetchCourseList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<CourseModel> = yield call(courseApi.getAll, action.payload);
    yield put(courseActions.fetchCourseListSuccess(response));
  } catch (error) {
    yield put(courseActions.fetchCourseListFailed());
  }
}

export default function* courseSaga() {
  yield takeLatest(courseActions.fetchCourseList.type, fetchCourseList);
}
