import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, RegistersDrivingRangesModel } from 'models';

export interface RegistersDrivingRangesState {
  loading: boolean;
  list: ResponseData<RegistersDrivingRangesModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: RegistersDrivingRangesState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const registersDrivingRangesSlice = createSlice({
  name: 'registersDrivingRanges',
  initialState,
  reducers: {
    fetchRegistersDrivingRangesList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchRegistersDrivingRangesListSuccess(state, action: PayloadAction<ResponseModel<RegistersDrivingRangesModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchRegistersDrivingRangesListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const registersDrivingRangesActions = registersDrivingRangesSlice.actions;

// Selectors
export const selectRegistersDrivingRangesList = (state: RootState) => state.registersDrivingRanges.list;
export const selectRegistersDrivingRangesLoading = (state: RootState) => state.registersDrivingRanges.loading;
export const selectRegistersDrivingRangesFilter = (state: RootState) => state.registersDrivingRanges.filter;
export const selectRegistersDrivingRangesTotal = (state: RootState) => state.registersDrivingRanges.total;

// Reducer
const registersDrivingRangesReducer = registersDrivingRangesSlice.reducer;
export default registersDrivingRangesReducer;
