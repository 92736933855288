import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ResponseData, ResponseModel, VehicleModel } from 'models';

export interface VehicleState {
  loading: boolean;
  list: ResponseData<VehicleModel>[];
  filter?: ListParams;
  total?: number;
}

const initialState: VehicleState = {
  loading: false,
  list: [],
  filter: {
    _page: 1,
    _limit: 10,
    _sort: '-created_at',
  },
  total: 0,
};

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    fetchVehicleList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchVehicleListSuccess(state, action: PayloadAction<ResponseModel<VehicleModel>>) {
      state.loading = false;
      state.list = action.payload.data;
      state.total = action.payload.meta?.count;
    },
    fetchVehicleListFailed(state) {
      state.loading = false;
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

// Actions
export const vehicleActions = vehicleSlice.actions;

// Selectors
export const selectVehicleList = (state: RootState) => state.vehicle.list;
export const selectVehicleLoading = (state: RootState) => state.vehicle.loading;
export const selectVehicleFilter = (state: RootState) => state.vehicle.filter;
export const selectVehicleTotal = (state: RootState) => state.vehicle.total;

// Reducer
const vehicleReducer = vehicleSlice.reducer;
export default vehicleReducer;
