import { PayloadAction } from '@reduxjs/toolkit';
import registersDrivingRangesApi from 'api/registersDrivingRangesApi';
import { ListParams, ResponseModel, RegistersDrivingRangesModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { registersDrivingRangesActions } from './registersDrivingRangesSlice';

function* fetchRegistersDrivingRangesList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<RegistersDrivingRangesModel> = yield call(
      registersDrivingRangesApi.getAll,
      action.payload,
    );
    yield put(registersDrivingRangesActions.fetchRegistersDrivingRangesListSuccess(response));
  } catch (error) {
    yield put(registersDrivingRangesActions.fetchRegistersDrivingRangesListFailed());
  }
}

export default function* registersDrivingRangesSaga() {
  yield takeLatest(registersDrivingRangesActions.fetchRegistersDrivingRangesList.type, fetchRegistersDrivingRangesList);
}
