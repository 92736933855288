import { PayloadAction } from '@reduxjs/toolkit';
import studentsApi from 'api/studentsApi';
import { ListParams, ResponseModel, StudentsModel } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { studentsActions } from './studentsSlice';

function* fetchStudentsList(action: PayloadAction<ListParams>) {
  try {
    const response: ResponseModel<StudentsModel> = yield call(studentsApi.getAll, action.payload);
    yield put(studentsActions.fetchStudentsListSuccess(response));
  } catch (error) {
    yield put(studentsActions.fetchStudentsListFailed());
  }
}

export default function* studentsSaga() {
  yield takeLatest(studentsActions.fetchStudentsList.type, fetchStudentsList);
}
