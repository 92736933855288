import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import authReducer from 'features/auth/authSlice';
import cityReducer from 'features/city/citySlice';
import courseReducer from 'features/course/courseSlice';
import dashboardReducer from 'features/dashboard/dashboardSlice';
import drivingLicenseReducer from 'features/driving-license/drivingLicenseSlice';
import drivingRangeTypeReducer from 'features/driving-range-type/drivingRangeTypeSlice';
import maintenanceSchedulesReducer from 'features/maintenance-schedules/maintenanceSchedulesSlice';
import registersDrivingRangesReducer from 'features/registers-driving-ranges/registersDrivingRangesSlice';
import registrationSchedulesReducer from 'features/registration-schedules/registrationSchedulesSlice';
import roleReducer from 'features/role/roleSlice';
import studentReducer from 'features/student/studentSlice';
import studentsReducer from 'features/students/studentsSlice';
import userReducer from 'features/user/userSlice';
import vehicleReducer from 'features/vehicle/vehicleSlice';
import createSagaMiddleware from 'redux-saga';
import { history } from 'utils';
import rootSaga from './rootSaga';

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  city: cityReducer,
  course: courseReducer,
  dashboard: dashboardReducer,
  drivingLicense: drivingLicenseReducer,
  drivingRangeType: drivingRangeTypeReducer,
  maintenanceSchedules: maintenanceSchedulesReducer,
  registersDrivingRanges: registersDrivingRangesReducer,
  registrationSchedules: registrationSchedulesReducer,
  role: roleReducer,
  student: studentReducer,
  students: studentsReducer,
  user: userReducer,
  vehicle: vehicleReducer,
});

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
