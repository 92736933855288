import { ListParams, RequestModel, ResponseModel, RoleModel } from 'models';
import axiosCustom from './axiosCustom';

const roleApi = {
  getAll(params: ListParams): Promise<RoleModel[]> {
    const url = '/api/dcm/roles';
    return axiosCustom.get(url, { params });
  },
  getById(id: string): Promise<ResponseModel<RoleModel>> {
    const url = `/api/dcm/roles/${id}`;
    return axiosCustom.get(url);
  },
  add(req: RequestModel<RoleModel>): Promise<ResponseModel<RoleModel>> {
    const url = '/api/dcm/roles';
    return axiosCustom.post(url, req);
  },
  update(req: RequestModel<RoleModel>): Promise<ResponseModel<RoleModel>> {
    const url = `/api/dcm/roles/${req.data.id}`;
    return axiosCustom.patch(url, req);
  },
  delete(id: string): Promise<ResponseModel<RoleModel>> {
    const url = `/api/dcm/roles/${id}`;
    return axiosCustom.delete(url);
  },
};

export default roleApi;
