export const REGISTRATION_SCHEDULES_CONST = {
  ADD_ERROR: 'Thêm lịch đăng kiểm thất bại!',
  ADD_SUCCESS: 'Thêm lịch đăng kiểm thành công!',
  ADD_TITLE: 'Thêm mới lịch đăng kiểm',
  CONFIRM_DELETE_CONTENT: 'Bạn có chắc muốn xóa lịch đăng kiểm',
  CONFIRM_DELETE_TITLE: 'Xác nhận xóa lịch đăng kiểm',
  REGISTRATION_SCHEDULES: 'Lịch đăng kiểm',
  DELETE_ERROR: 'Xóa lịch đăng kiểm thất bại!',
  DELETE_SUCCESS: 'Đã xóa lịch đăng kiểm thành công!',
  EDIT_TITLE: 'Cập nhật lịch đăng kiểm',
  NAME: 'Nội dung đăng kiểm',
  UPDATE_ERROR: 'Cập nhật lịch đăng kiểm thất bại!',
  UPDATE_SUCCESS: 'Cập nhật lịch đăng kiểm thành công!',
  START_DATE: 'Ngày đến hạn',
  END_DATE: 'Ngày hết hạn',
};
