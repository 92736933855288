import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CookieUtils } from 'utils/cookie-utils';

const axiosCustom = axios.create({
  // baseURL: 'http://192.168.101.242:30101',
  baseURL: 'https://app.mekongsmartcam.vn/edge',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosCustom.interceptors.request.use((config: AxiosRequestConfig) => {
  const sessionToken = CookieUtils.getCookie('SESSION');
  if (sessionToken) {
    config.headers.session = sessionToken;
  }

  return config;
});

axiosCustom.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      if (window.location.pathname !== '/dang-nhap') {
        window.location.href = '/dang-nhap';
      }
    }
    // throw error;
    throw error.response.data.errors;
  },
);

export default axiosCustom;
