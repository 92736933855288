import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, fork, put, take } from 'redux-saga/effects';
import { authActions, LoginPayload } from './authSlice';
import { toast } from 'react-toastify';
import meApi from 'api/meApi';
import { CookieUtils } from 'utils/cookie-utils';
import { UserModel } from 'models/user.model';

function* handleLogin(payload: LoginPayload) {
  try {
    // yield delay(500);
    const data: UserModel = yield call(meApi.login, payload.username, payload.password);
    if (data.session?.key) {
      CookieUtils.setCookie('SESSION', data.session.key);
      localStorage.setItem('user_info', JSON.stringify(data));

      // Handle remember me
      localStorage.removeItem('remember');
      if (payload.remember) {
        localStorage.setItem('remember', JSON.stringify({ username: payload.username, password: payload.password }));
      }
      yield put(authActions.loginSuccess(data));
    }
    // redirect to admin page
    yield put(push('/admin/hoc-vien'));
  } catch (error) {
    yield put(authActions.loginFailed('Vui lòng kiểm tra lại thông tin đăng nhập của bạn và thử lại.'));
  }
}

function* handleLogout() {
  // yield delay(500);
  const { loggedoutAt } = yield call(meApi.logout);
  if (!loggedoutAt) {
    toast.error('Logout error!!!');
    return;
  }
  CookieUtils.deleteCookie('SESSION');

  // Clear everything except remember
  const remember = localStorage.getItem('remember');
  localStorage.clear();
  if (remember) {
    localStorage.setItem('remember', remember);
  }

  // redirect to login page
  yield put(push('/dang-nhap'));
}

function* watchLoginFlow() {
  while (true) {
    const sessionToken = CookieUtils.getCookie('SESSION');
    const isLoggedIn = Boolean(localStorage.getItem('user_info'));

    if (!isLoggedIn || !sessionToken) {
      const action: PayloadAction<LoginPayload> = yield take(authActions.login.type);
      yield call(handleLogin, action.payload);
    } else {
      yield take(authActions.logout.type);
      yield call(handleLogout);
    }
  }
}

export default function* authSaga() {
  yield fork(watchLoginFlow);
}
